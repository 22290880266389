import { Controller } from 'stimulus';
import axios from 'axios';

export default class PerformanceReport extends Controller {
  static targets = ["review",  "user"]

  initialize() {
    if (this.hasUserTarget) {
      this.fetchUserData(this.userTarget.value)
    }
  }

  fetchUserData(userId) {
    axios.get("/observe/performance_reviews/retrieve_available_reviews.json", { params: { tenant_user_id: userId } })
    .then(response => {

      this.reviewTarget.innerHTML = ""

      let values = response.data

      for(var i=0; i < values.length; ++i ){
        let option = document.createElement("option")
        option.value = values[i].id
        option.text = values[i].name
        this.reviewTarget.append(option)
      }
    })
  }

  changeUser(event) {
    event.preventDefault()
    this.fetchUserData(this.userTarget.value)
  }

  disconnect() {}
}
