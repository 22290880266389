import { Controller } from 'stimulus'
import $ from 'jquery';

export default class HtmlSelect2 extends Controller {
  initialize () {
  }
  connect() {

    $(this.element).select2({
      theme: 'bootstrap',
      width: '100%', 
      escapeMarkup: function (text) { return text; }
    })
  }


  disconnect() {
    $(this.element).select2('destroy')
  }
}
