import { Controller } from 'stimulus'


export default class ObservableLimit extends Controller {
  static targets = ["observeActivated", "limit"]

  initialize () {
    let limit = $(this.limitTarget);

    limit.hide();
    if ($(this.observeActivatedTarget).is(":checked")) {
      limit.show();
    }

    $(this.observeActivatedTarget).on('switchChange.bootstrapSwitch', function (event) {
      if ($(this).is(":checked")) {
        limit.show();
      }
      else {
        limit.hide();
      }
    });
  }

  connect() {}

  disconnect() {}
}
