import LoadSummaryReport from "./load_summary_report_controller";
import axios from 'axios';

export default class QuestionReportController extends LoadSummaryReport {
  static targets = ["question", "spinner"]

  initialize() {

    if (this.hasQuestionTarget) {
      let targets = this.questionTargets
      let report = this
      async function questionLoop () {
        console.log('Start')
        let length = targets.length;
        for (let i = 0; i < length; i += 1) {
          const target = targets[i]
          const position = (i === ( length - 1 ));
          const questionData = await report.questionData(target, position)
        }
      }
    questionLoop();
    }
    else {
      $(this.spinnerTarget).hide();
    }
  }

  questionData(target, position) {
    let element = document.getElementById(target.id);
    axios.get(target.dataset.url)
    .then(response => {
      element.insertAdjacentHTML('afterend', response.data)
      if ($('#full-report-toggle')[0].checked === false) {
        $('.condensedTable').hide();
        $("#graphs").show();
        $('.full-report-table').show();
      } else {
        $('.condensedTable').show();
        $("#graphs").hide();
        $('.full-report-table').hide();
      }
      $('#' + target.dataset.spinner).hide();
        $('#' + target.dataset.title).hide();
      $('[data-toggle="tooltip"]').tooltip();
      this.drawGraphs();
      this.sparkLineGraphs();
      this.scoreGraphs();
      if (position === true) {
        $(this.spinnerTarget).hide();
      }
    })
  }
}