import { Controller } from 'stimulus'
import $ from 'jquery';

export default class ListGroup extends Controller {
  // static targets = ["selectInput"]

  connect() {

    $(function() {

      $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        sessionStorage.setItem("tab:"+document.URL, $(e.target).attr('id'));
      });
    
      //go to the latest tab, if it exists:
      var lastTab = sessionStorage.getItem("tab:"+document.URL);
      if (lastTab) {
        $('#'+lastTab).tab('show');
      }
    
      //when a group is shown, save it as the active accordion group
      $(this.element).on('shown.bs.collapse', function (e) {
          var id = $(e.target).attr('id');
          var list_groups = sessionStorage.getItem("bs-list-group:"+document.URL);
          if (list_groups) {
            list_groups = JSON.parse(list_groups);
          } else {
            list_groups = {};
          }
          list_groups[id] = true;
          sessionStorage.setItem("bs-list-group:"+document.URL, JSON.stringify(list_groups));
      });
      $(this.element).on('hidden.bs.collapse', function (e) {
        var id = $(e.target).attr('id');
        var list_groups = sessionStorage.getItem("bs-list-group:"+document.URL);
        if (list_groups) {
          list_groups = JSON.parse(list_groups);
          delete list_groups[id];
          sessionStorage.setItem("bs-list-group:"+document.URL, JSON.stringify(list_groups));
        }
      });
    
      var list_groups = sessionStorage.getItem("bs-list-group:"+document.URL);
      if (list_groups) {
        //remove default collapse settings
        $(".list-group .collapse").removeClass('in');
        list_groups = JSON.parse(list_groups);
        for(var id in list_groups) {
          //show the account_last visible group
          $("#" + id).addClass("in");
          $("#chevron-" + id).addClass("glyphicon-menu-down");
        }
      }
    });
  }
}

