import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  initialize() {
    let options = {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: 'n/j/Y h:iK',
      allowInput: true,
      clickOpens: false,
      wrap: true
    }

    flatpickr(this.element, options)
  }

}
