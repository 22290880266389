import { Controller } from 'stimulus'
import $ from 'jquery';
import videojs from 'video.js'

export default class VideoPlayer extends Controller {
  static targets = ["video", "previousProgress"]

  connect () {

    let progress = 0;
    let progressUrl = this.videoTarget.dataset.url;
    let record = "no"
    let latestVideoPosition = 0
    let currentTime = 0
    let id = this.videoTarget.id;

    if (this.hasPreviousProgressTarget && $("#learn_digital_asset_progress_percentage_complete").val() != 100 ) {
      $('#next-button').hide();
      progress = this.videoTarget.dataset.progress
      record = "yes"
    }
    else {
      $('#next-button').show();
    }


     videojs.hook('setup', function(player) {
      //  player.controlBar.progressControl.hide()

       if (player.id_ == id ) {
          function seekBasedOnProgress(player) {
            if (record === "yes") {
              latestVideoPosition = parseInt(progress);
            }
          else {
              latestVideoPosition = currentTime;
          }

            if (record === 'yes' && latestVideoPosition < player.currentTime()) {
              player.currentTime(latestVideoPosition);
            }
          }

          player.on("seeking", function(event) {
            console.log('seeking')
            seekBasedOnProgress(player);
          });

          setInterval(function() {
            if (!player.paused()) {
              currentTime = player.currentTime();
            }
          }, 1000);

          player.on("seeked", function(event) {
            console.log('seeked')
            if (record === "yes" && player.currentTime() > progress) {
              player.currentTime(progress)
            }
          });

          if (record === "yes") {
            player.on("ended", function() {
              if(parseInt($("#learn_digital_asset_progress_percentage_complete").val()) !== 100) {
                let data = {
                  learn_digital_asset_progress: {
                    percentage_complete: 100,
                    video_position: this.duration()
                  }
                };
                $.ajax({
                  type: 'PUT',
                  url: progressUrl,
                  data: data,
                  cache:  false,
                  async:  true
                });
                console.log("video has finished");
                this.lastTime = this.duration();
                $("#learn_digital_asset_progress_percentage_complete").val(100);
                $("#learn_digital_asset_progress_video_position").val(this.duration());
                $('#next-button').show();
              }
              else {
                // bar.enable()
              }
            });

            player.on("timeupdate", function() {
              if (parseInt($("#learn_digital_asset_progress_percentage_complete").val()) !== 100) {
                var ct = parseInt(this.currentTime());
                // only eval once per second inc, since timeupdate pops ~4 times per second
                  // if current time is divisible by 5 then an inc of 10s has passed
                if (ct % 5 === 0 && ct !== parseInt($("#learn_digital_asset_progress_video_position").val())) {
                  // TODO: AJAX to controller to update digital asset progress
                  console.log(ct,'seconds have passed');
                  if ( (ct - 30) >= progress ) {
                    progress = ct;
                    console.log('update');
                    let updateData = {
                      learn_digital_asset_progress: {
                        percentage_complete: parseInt((this.currentTime() / this.duration()) * 100),
                        video_position: this.currentTime()
                      }
                    };

                    $.ajax({
                      type: 'PUT',
                      url: progressUrl,
                      data: updateData,
                      cache:  false,
                      async:  true
                    });
                    this.lastTime=ct;
                  }
                }
              }
            });
          }
        }
    });


    videojs(document.getElementById(id), {
      html5: {
        hls: {
          overrideNative: !videojs.browser.IS_SAFARI,
          withCredentials: true
        },
        nativeAudioTracks: true
      },
      textTrackSettings: true
    });
  }




  disconnect() { }
}
