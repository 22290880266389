import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'form' ]
  static values = { getUrl: String, frame: String }

  initialize() {}

  submit(event) {
    event.preventDefault()
    this._getForm(event).requestSubmit()
  }

  submitGet(event) {
    event.preventDefault()
    Turbo.visit(this._urlWithFormData(event), {frame: this.frameValue})
  }

  _getForm(event) {
    if (this.hasFormTarget) {
      return this.formTarget
    }

    if (event.target.dataset.formId) {
      return document.getElementById(event.target.dataset.formId)
    }

    return this.element
  }

  _urlWithFormData(event) {
    if (!this.hasGetUrlValue) {
      return
    }

    const form = this._getForm(event)
    const params = new URLSearchParams(new FormData(form))
    const url = new URL(this.getUrlValue, window.location.origin)
    url.search = [url.searchParams.toString(), params.toString()].join('&')

    return url
  }
}
