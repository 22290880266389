import { Controller } from 'stimulus'

export default class PublishVideoController extends Controller {


  initialize () {
    $(document).ready(function() {

      $("#learn_video_digital_media").on('change',function(){
          //var fileUrl = window.URL.createObjectURL(this.files[0]);
          //$("#sample_video").attr("src", fileUrl);
          var $source = $('#sample_video_source');
          $source[0].src = URL.createObjectURL(this.files[0]);
          $source.parent()[0].load();
      });
      $("#capture_frame").on('click', function() {
        var frame = captureVideoFrame('sample_video', 'png');

        // Show the image
        var img = document.getElementById('my_screenshot');
        img.setAttribute('src', frame.dataUri);


        $("#learn_video_image").value(frame.dataUri);

      });
      //$('#divVideo video source').attr('src', videoFile);

      function captureVideoFrame(video, format) {
        if (typeof video === 'string') {
          video = document.getElementById(video);
        }

        format = format || 'jpeg';

        if (!video || (format !== 'png' && format !== 'jpeg')) {
          return false;
        }

        var canvas = document.createElement("CANVAS");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        canvas.getContext('2d').drawImage(video, 0, 0);

        var dataUri = canvas.toDataURL('img/' + format);
        var data = dataUri.split(',')[1];
        var mimeType = dataUri.split(';')[0].slice(5)

        var bytes = window.atob(data);
        var buf = new ArrayBuffer(bytes.length);
        var arr = new Uint8Array(buf);

        for (var i = 0; i < bytes.length; i++) {
          arr[i] = bytes.charCodeAt(i);
        }

        var blob = new Blob([ arr ], { type: mimeType });
        return { blob: blob, dataUri: dataUri, format: format };
      }
    });
  }

  connect() {}


  disconnect() {}
}