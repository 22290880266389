import { Controller } from 'stimulus';

export default class Slick extends Controller {
  static targets = ["products"]

  initialize () {
    $(this.productsTarget).not('.slick-initialized').slick({
      dots: false,
      variablewidth: true,
      arrows: true,
      infinite: true,
      centerMode: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      respondTo: 'min',
      responsive: [
        {
          breakpoint: 977,
          settings: {
            variablewidth: true,
            arrows: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 753,
          settings: {
            variablewidth: true,
            arrows: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 497,
          settings: {
            variablewidth: true,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
  
  connect() {}


  disconnect() {}
}
