import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["well", "resetDate"]

  initialize() {}

  connect() {}

  changeReset(event) {
    event.preventDefault()

    if (event.target.value === "") {
      this.wellTarget.hidden = true
    } else {
      this.wellTarget.hidden = false

      if (event.target.value === "1") {
        this.resetDateTarget.hidden = false
      } else {
        this.resetDateTarget.hidden = true
      }
    }
  }
}
