import { Controller } from 'stimulus';
import $ from 'jquery';
import axios from 'axios';

export default class AutoFinalize extends Controller {
  static targets = ["role"]

  initialize() {
    let show = false
    $('#auto-finalize').hide()
    this.roleTargets.forEach(function(target) {
      if (target.id === "observer") {
        if (target.children[0].checked) {
          show = true
        }
      }
    })
    if (show == true) {
      $('#auto-finalize').show();

    }
  }

  fetchInput(event) {
    event.preventDefault()
    let show = false
    $('#auto-finalize').hide()
    this.roleTargets.forEach(function(target) {
      if (target.id === "observer") {
        if (target.children[0].checked) {
          show = true
        }
      }
    })
    if (show == true) {
      $('#auto-finalize').show();
    }
  }
  disconnect() {}

}
