import { Controller } from 'stimulus'
import $ from 'jquery';

export default class LocationReload extends Controller {
  static targets = []

  initialize () {}
  
  connect() {}

  reload(event) {
    location.reload();
  }


  disconnect() {}
}
