import { Controller } from 'stimulus';
import $ from 'jquery';

export default class PrintButton extends Controller {
  initialize () {
    $(".print-button").click(function() {
      let w = window.open();
      w.document.write($('#print_image').html());
      w.print();
      w.close();
    })
  }
}