import { Controller } from 'stimulus'

export default class ResellerBulkMessage extends Controller {


  initialize () {
    $('#text-preview').hide();

    $('#preview-button').click(function() {
      $('#form-table').removeClass('col-xs-12');
      $('#form-table').addClass('col-xs-6');
      var text_body = document.getElementById('email_template_html_body').value;
      $('#text-preview')[0].innerHTML = text_body
      $('#text-preview').show();
    });
  }

  connect() {}


  disconnect() {}
}