import Evaluation from "./evaluation_controller";

export default class PerformanceReview extends Evaluation {
  setCounterQuestions() {
    $('[id^="add-counter"]').bind('mousedown touchstart', function (event) {
      event.preventDefault();
      var id = "counter_observation_answers_" + $(this).attr("postfix");
      var counter = $('input[id^="' + id + '"]').val();
      if (isNaN(counter) || counter == '') {
        counter = 0;
      } else {
        counter = parseInt(counter);
      }
      counter = counter + 1;
      if (counter > 50) counter = 50;
      $('input[id^="' + id + '"]').val(counter);
    });
    $('[id^="subtract-counter"]').bind('mousedown touchstart', function (event) {
      event.preventDefault();
      var id = "counter_observation_answers_" + $(this).attr("postfix");
      var counter = $('input[id^="' + id + '"]').val();
      if (isNaN(counter) || counter == '') {
        counter = 0;
      } else {
        counter = parseInt(counter);
      }
      counter = counter - 1;
      if (counter < 0) counter = 0;
      $('input[id^="' + id + '"]').val(counter);
    });
  }

  setRequiredCourseUser() {
    let tenantUser = document.getElementById('tenant-user-id')
    $(".button_with_tenant_user_id").show();
    $('.button_with_tenant_user_id').each(function() {
      this.href = this.href + '&tenant_user_id=' + tenantUser.dataset.value;
    });
  }

  setAnswerValues() {
    $('.mt-radio').click(function() {
      $('.answer-value', this)[0].checked = true;
      $('.answer-value', this)[1].checked = true;
      $('#performance_review-form').append(
        $("<input>", 
          {
            type: "hidden",
            name: this.children[1].name, 
            value: this.children[1].value }
        )
      );
    });
  }
  confirm(event) {
    event.preventDefault()
    if ($('.qq-thumbnail-selector').length === $('.qq-upload-success').length) {
      if (event.target.id == "finalize-observation" ){
        let input = document.createElement("input");
                input.type = "hidden";
                input.name = "observe_performance_review[closed]"
                input.value = true
                this.formTarget.appendChild(input);
      }
      else if (event.target.id == "save-observation" ){
        let input = document.createElement("input");
                input.type = "hidden";
                input.name = "observe_performance_review[closed]"
                input.value = false
                this.formTarget.appendChild(input);
      }

      this.formTarget.submit()
      $('.new-observation').hide();
      $('#submitting-spinner').show();
      $('#observe-confirm-footer').hide();
      $('#observe-text').hide();
    } else {
      event.preventDefault();
      window.scrollTo(0, 0);
      $("#incomplete-uploads-message").show();
      $("#confirm-modal").hide()
    }
  }
}