import { Controller } from 'stimulus';
import $ from 'jquery';
import axios from 'axios';

export default class SectionRubric extends Controller {
  static targets = ["section"]
  initialize() {
    var formSection = this.sectionTarget.id

    document.ondragstart = function(event) {
      event.dataTransfer.setData("Text", event.target.id);
      var questionId = event.target.dataset.question;
      var object = document.getElementsByClassName(questionId);
      $(object).addClass('highlight-rubric');
    };

    /* Events fired on the drop target */
    document.ondragover = function(event) {
      event.preventDefault();
    };

    document.ondrop = function(event) {
      var markerUrl = "/observe/question_choice_markers %>";
      event.preventDefault();
      var data = event.dataTransfer.getData("Text");
      if (event.target.classList.value == "droptarget"){
        let questionChoice = data
        let rubricMarker = event.target.id
        event.target.appendChild(document.getElementById(data));
        let objectClass = event.target.dataset.question + " highlight-rubric"
        var object = document.getElementsByClassName(objectClass);
        console.log(object)

        $(object).removeClass('highlight-rubric');
        axios.post("/observe/question_choice_markers",  {question_choice_id: questionChoice, rubric_marker_id: rubricMarker, id: formSection})
        .then(response => {
          event.target.load
        })
      }
    };
  }
}