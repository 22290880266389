import { Controller } from 'stimulus';
import axios from 'axios';
import $ from 'jquery';

export default class Organization extends Controller {
  static targets = ["parent", "organization"]


  initialize() {
    this.fetchData(this.parentTarget.value)
  }

  connect() {
  }

  fetchData(organizationId) {

    axios.get("/reseller/organizations/retrieve_available_organizations.json", { params: {organization_id: organizationId, term: term}})
    .then(response => {
      
      this.organizationTarget.innerHTML = ""
  
      let values = response.data


      for(var i=0; i < values.length; ++i ){
          let option = document.createElement("option")
          option.value = values[i].id
          option.text = values[i].name
          this.formsetTarget.append(option)

      }

    })
  }

  changeOrganization(event) {
    event.preventDefault()
    this.fetchData(this.organizationTarget.value)
  }
}