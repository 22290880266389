import { Controller } from 'stimulus'
import $ from 'jquery';

export default class PrintObject extends Controller {
  static targets = ["printImage"]

  initialize () {
  }
  connect() {}

  print (event) {
    event.preventDefault()
    console.log("print")
    let w = window.open();
    w.document.write($(this.printImageTarget).html());
    w.print();
    w.close();
  }

  disconnect() {}
}