import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.modalElement = document.getElementById(this.element.dataset.modalId || 'shared-modal');

    $(this.modalElement).on("hidden.bs.modal", () => {
      this.modalElement.innerHTML = '';
    });
  }

  close(event) {
    event.preventDefault();

    if (event.detail.success) {
      if (this.element.dataset.closeModal) {
        const closeModal = document.getElementById(this.element.dataset.closeModal);
        if (closeModal) $(this.modalElement).modal('hide');
      } else {
        $(this.modalElement).modal('hide');
      }
    }
  }

  disconnect() {
    const existingModal = $(this.modalElement);

    if (existingModal && this.disposeValue) {
      existingModal.dispose();
    }
  }
}
