import { Controller } from 'stimulus'
import axios from 'axios'
import Infinitev2 from './infinitev2_controller'

export default class extends Infinitev2 {
  loadItems(items) {
    items.forEach(function(item, key) {
      let row = this.containerBodyTarget.insertRow()
      Object.values(item).forEach(function(value) {
        let cell = row.insertCell()
        cell.innerHTML = value
      })
    }, this)
  }
}
