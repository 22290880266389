import { Controller } from 'stimulus';
import axios from 'axios';

export default class ShowObservation extends Controller {
  static targets = ["answer", "value", "choice", "box", "attachments", "video", "discussionInput", "addInput"]

  initialize () {
    if (this.hasVideoTargets) {
      this.videoTargets.forEach(function(target ){
        let playerId = player + target.dataset.id
        let videoId = "video-" + target.dataset.id
        let attachmentId = "#attachment" + target.dataset.id
        playerId = {}

        $(attachmentId).on('ajax:complete', function() {
          if (videojs.getPlayers()[videoId] != undefined ){
            playerId.dispose();
          }

          playerId = videojs(attachmentId, {
            html5: {
              hls: {
                withCredentials: true
              }
            },
            fluid: true
          });
        })

        $('#shared-modal').on('hide.bs.modal', function() {
          var vid = videojs.getPlayers()[videoId];
          if (vid != undefined){
            vid.pause();
          }
        });

      })
    }
  }

  discussion (event) {
    event.preventDefault()
    let discussionId = "#discussion-" + event.target.id
    $(discussionId).toggle()
  }

  addComment (event) {
    event.preventDefault()
    let addComment = "#add-comment-" + event.target.id
    $(addComment).toggle()
  }

  showUploadModal(event) {
    event.preventDefault()
    let modal = "#attachments-" + event.target.id
    $(modal).modal({backdrop: 'static', keyboard: false});
    $(this.attachmentsTarget).not('.slick-initialized').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1
    });

  }

  showCommentModal(event) {
    event.preventDefault()
    let modal = "#comments-" + event.target.id
    $(modal).modal({backdrop: 'static', keyboard: false});
  }

  disconnect() {}


}