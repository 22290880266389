import { Controller } from 'stimulus'

 export default class ShowModal extends Controller {

  initialize () {
    $('#shared-modal').modal({backdrop: 'static', keyboard: false});
   }
   connect() {}
   disconnect() {}
}
