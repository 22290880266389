import { Controller } from 'stimulus';

import axios from 'axios';

export default class TenantUser extends Controller {
  static targets = ["organization", "scheduleInvite", "sendInvitation", "spinner"]

  initialize() {
    if (this.hasOrganizationTarget) {
      $(this.organizationTarget).select2({
        theme: 'bootstrap',
        width: '100%'
      });
      $(this.organizationTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event);
      });
    }

    if (this.hasSendInvitationTarget) {
      let target = this.scheduleInviteTarget
      $(this.sendInvitationTarget).bootstrapSwitch({})
      $(this.sendInvitationTarget).on('switchChange.bootstrapSwitch', function (event) {
        if (this.checked === true) {
          $(target).show()
        } else {
          $(target).hide()
        }
      });
    }
  }

  displaySpinner(event){
    event.preventDefault()
    $(this.spinnerTarget).show();
    $(event.target).hide()
  }

  disconnect() {
    if (this.hasOrganizationTarget) {
      $(this.organizationTarget).select2('destroy')
     }
  }
}
