// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//public packages
require("@rails/ujs").start()
import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("lodash")
require('axios')
require('flatpickr')
require("flatpickr/dist/flatpickr.css")

require("@nathanvda/cocoon")
import 'controllers'

require("highcharts")

window.JQuery = $;
window.$ = $;

require("@mersoft-corp/metronic/assets/global/plugins/bootstrap/js/bootstrap.min")
require("@mersoft-corp/metronic/assets/global/plugins/bootstrap-table/bootstrap-table.min")
require("@mersoft-corp/metronic/assets/global/plugins/jquery.blockui.min")
require("@mersoft-corp/metronic/assets/global/plugins/jquery-slimscroll/jquery.slimscroll.min")
require("@mersoft-corp/metronic/assets/global/plugins/select2/js/select2.full.min")
require("bootstrap-switch/dist/js/bootstrap-switch.min")
require("@mersoft-corp/metronic/assets/global/scripts/app.min")
require("slick-carousel/slick/slick.min")
require("video.js/dist/video.js")
require("@videojs/http-streaming/dist/videojs-http-streaming.min")
require("@mersoft-corp/metronic/assets/global/plugins/bootstrap/css/bootstrap.min.css")
require("@mersoft-corp/metronic/assets/global/plugins/font-awesome/css/font-awesome.min.css")
require("@mersoft-corp/metronic/assets/global/plugins/select2/css/select2.min.css")
require("@mersoft-corp/metronic/assets/global/plugins/select2/css/select2-bootstrap.min.css")
require("@mersoft-corp/metronic/assets/global/plugins/simple-line-icons/simple-line-icons.min.css")
require("bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css")
require("@mersoft-corp/metronic/assets/global/plugins/bootstrap-table/bootstrap-table.min.css")
require("@mersoft-corp/metronic/assets/global/css/components.min.css")
require("@mersoft-corp/metronic/assets/global/css/plugins.min.css")
require("@mersoft-corp/metronic/assets/global/css/blog.min.css")
require("@mersoft-corp/metronic/assets/layouts/layout/css/layout.min.css")
require("@mersoft-corp/metronic/assets/layouts/layout/css/themes/blue.min.css")
require("@mersoft-corp/metronic/assets/layouts/layout/css/themes/light2.min.css")
require("@mersoft-corp/metronic/assets/pages/css/login-3.min.css")
require("@mersoft-corp/metronic/assets/pages/css/error.min.css")
require("@mersoft-corp/s3.fine-uploader/fine-uploader-gallery.min.css")
require("slick-carousel/slick/slick.css")
require("slick-carousel/slick/slick-theme.css")
require('video.js/dist/video-js.css')
require("@mersoft-corp/s3.fine-uploader")

require("stylesheets/application.css")

const images = require.context('../images', true)
