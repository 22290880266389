import { Controller } from 'stimulus'

export default class DismissModal extends Controller {
  static targets = ["mainModal"]

  initialize () {
    $('#shared-modal').modal('hide');
  }

  connect() {}

  hideModal(event) {
    event.preventDefault()
    $(this.mainModalTarget).hide();
  }

  closeModal(event) {
    event.preventDefault()
    $(this.mainModalTarget).hide();
  }


  disconnect() {}
}
