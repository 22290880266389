import { Controller } from 'stimulus'
import $ from 'jquery';

export default class UserWelcome extends Controller {
  static targets = []

  initialize () {
    $('#mt_user_welcome').modal('show');
  }
  connect() {}


  disconnect() {}
}