import { Controller } from 'stimulus'
import $ from 'jquery';

export default class Comment extends Controller {
  static targets = ["toggle", "addInput", "discussionInput"]

  initialize () {
    if (this.hasToggleTargets) {
      $(this.toggleTarget).click(function(){
        $(this.toggleTarget).toggle()
      })
    }
  }
  
  connect() {}

  discussion (event) {
    event.preventDefault()
    $(this.discussionInputTarget).toggle()
  }

  addComment (event) {
    event.preventDefault()
    $(this.addInputTarget).toggle()
  }


  disconnect() {}
}
