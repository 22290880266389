import { Controller } from 'stimulus'
import $ from 'jquery';

export default class ResellerCustomize extends Controller {
  static targets = ["tenantAccess", "propagate"]

  initialize () {
    let target = this.tenantAccessTarget
    $(this.tenantAccessTarget).bootstrapSwitch({})
    $(this.tenantAccessTarget).on('switchChange.bootstrapSwitch', function (event, state) {
      $(target).closest('form').submit();
    });

    let propagate = this.propagateTarget
    $(this.propagateTarget).bootstrapSwitch({})
    $(this.propagateTarget).on('switchChange.bootstrapSwitch', function (event, state) {
      $(propagate).closest('form').submit();
    });
  }
  
  connect() {}


  disconnect() {}
}