import { Controller } from 'stimulus'

export default class MakeSwitch extends Controller {
  static targets = ["hide"]
  initialize() {

  }
  connect() {
    $(this.element).bootstrapSwitch()
  }

}