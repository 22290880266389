import { Controller } from 'stimulus'
import $ from 'jquery';

export default class Select2KeepOpen extends Controller {
  static targets = ["hide", "product"]

  initialize () {
  }
  connect() {
    $(this.element).select2({
      theme: 'bootstrap',
      width: '100%',
      closeOnSelect: false
    })
    if (this.hasHideTarget) {
      this.hideTargets.forEach(function(target) {
        target.hide();
      })
    }
  }

  changeProduct(event) {
  }

  disconnect() {
    $(this.element).select2('destroy')
  }
}
