import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class DatePicker extends Controller {
  initialize() {
    let options = {
      allowInput: true,
      dateFormat: 'Y-m-d',
      altFormat: 'n/j/Y',
      altInput: true,
      clickOpens: false,
      wrap: true
    }
    flatpickr(this.element, options)
  }

  disconnect() {}
}
