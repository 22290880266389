import { Controller } from 'stimulus'
import $ from 'jquery';

export default class ResellerOrg extends Controller {
  static targets = ["provider", "enableProvider"]

  initialize () {
    if ( $('#provider_type').val() != "SamlProvider") {
      $('#saml-sso-fields').hide();
   }
   
   if ( $('#provider_type').val() != "MicrosoftProvider") {
      $('#admin-consent-fields').hide();
   }

   if ( $('#provider_type').val() != ("IbmVerifyProvider" || "OpenidConnectProvider")) {
      $('#openid-connect-fields').hide();
    }

   if ($('#enable-sso').is(":checked")) {
      $('#sso-provider').show();
    }
    else {
      $('#sso-provider').hide();
    }
    if (this.hasProviderTarget) {
      $(this.providerTarget).select2({
        theme: 'bootstrap',
        width: '100%'
      });

      $(this.providerTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event);
      });
      
      $(this.enableProviderTarget).bootstrapSwitch({})

      $(this.enableProviderTarget).on('switchChange.bootstrapSwitch', function (event, state) {
        if ($("input[id^='enable-sso']")[0].checked == true) {
          $('#sso-provider').show();
        }
        else {
          $('#sso-provider').hide();
        }
      });
    }
  }


  providerFields(event) {
    if (event.target.value == "SamlProvider" ) {
      $('#saml-sso-fields').show();
      $('#admin-consent-fields').hide();
      $('#openid-connect-fields').hide();
    }
    else if (event.target.value == "MicrosoftProvider" ) {
      $('#admin-consent-fields').show();
      $('#saml-sso-fields').hide();
      $('#openid-connect-fields').hide();
    }
    else if (event.target.value == "IbmVerifyProvider" || event.target.value == "OpenidConnectProvider") {
      $('#openid-connect-fields').show();
      $('#saml-sso-fields').hide();
      $('#admin-consent-fields').hide();
    } else {
      $('#admin-consent-fields').hide();
      $('#saml-sso-fields').hide();
      $('#openid-connect-fields').hide();
    }

  }

  connect() {
  }


  disconnect() {
  }
}