import { Controller } from 'stimulus';
import axios from 'axios';
import $ from 'jquery';

export default class SummaryReport extends Controller {
	static targets = ["table", "message", "criteria", "formSet", "filterForm", "filterInput", "dependLink", "timeInput", "fullReport", "graphs", "mime", "export", "section"]

	initialize() {
		let summaryReport = this
		// window.addEventListener("DOMContentLoaded", function(event) {
		summaryReport.documentOnLoad()
		// });
	}

	documentOnLoad() {
		$(this.timeInputTarget).select2({
			theme: 'bootstrap',
			tags: true,
			width: '200px',
			tokenSeparators: [',', ' ']
		});

		$(this.timeInputTarget).on('select2:select', function () {
			let event = new Event('change', {
				bubbles: true
			})
			this.dispatchEvent(event);
		});

		$(this.graphsTarget).bootstrapSwitch({})
		$(this.graphsTarget).on('switchChange.bootstrapSwitch', function (event, state) {
			let reportGraphs = document.querySelectorAll('.summary-graphs'),
				i = 0,
				l = reportGraphs.length;

			for (i; i < l; i++) {
				if (state === false) {
					reportGraphs[i].style.display = 'none';
				} else {
					reportGraphs[i].style.display = "";
				}
			}
		});

		$(this.fullReportTarget).bootstrapSwitch({})
		$(this.fullReportTarget).on('switchChange.bootstrapSwitch', function (event, state) {
			if (state === false) {
				$('.condensedTable').hide();
				$("#graphs").show();
				$('.full-report-table').show();
			} else {
				$('.condensedTable').show();
				$("#graphs").hide();
				$('.full-report-table').hide();
			}
		});

		this.params = new URLSearchParams()
		this.isLoading = false
		this.tableData = []
		this.tableBody = this.tableTarget.getElementsByTagName('tbody')[0] || this.tableTarget
		this.filter()
	}

	fetchData(clear = false) {
		if (this.isLoading) return

		this.isLoading = true

		this.displaySpinner()
		axios.get(this.tableTarget.dataset.url, {
				params: this.params
			})
			.then(response => {
				this.load(response.data.records.menu)
				$('.condensedTable').hide();
				this.hideSpinner()
				this.displayCriteria(response.data.criteria)
				this.isLoading = false
			})
	}

	filter() {
		if (this.hasFilterInputTarget) {
			this.filterInputTargets.forEach(function (target) {
				this.params.delete(target.name)
				if (target.tagName === 'SELECT') {
					let selections = target.selectedOptions
					for (let i = 0; i < selections.length; i++) {
						this.params.append(target.name, selections[i].value)
					}
				}
				if (target.tagName === 'INPUT') {
					if (target.type === 'checkbox') {
						if (target.checked) {
							this.params.set(target.name, target.value)
							if (target.name == "full_report") {
								$("#graphs").hide()
								this.params.set(target.name, false);
							}
							if (target.name == "report_criteria") {
								this.params.set(target.name, true);
							}
						} else if (target.name == "full_report") {
							$("#graphs").show();
							this.params.set(target.name, true);
						} else if (target.name == "graphs") {
							this.params.set(target.name, false);
						}
					} else {
						this.params.append(target.name, target.value)
					}
				}
			}, this)
		}
		this.setDependLinks()
		this.fetchData(true)
	}

	clear() {
		this.tableTarget.innerHTML = ""
	}

	submitReport(event) {
		event.preventDefault()
		this.filter()
	}

	load(menu) {
		this.tableTarget.innerHTML = ""
		let parentDiv = document.createElement("div")
		parentDiv.innerHTML = menu
		this.tableTarget.append(parentDiv)
	}

	displayCriteria(criteria) {
		if (criteria === "") {
			this.criteriaTarget.innerHTML = ""
		} else {
			let clearSnippet = `
				<a href="#" data-action="click->${this.scope.identifier}#clear">
					<i class="fa fa-times-circle text-danger"></i>
				</a>
			`
			this.criteriaTarget.innerHTML = clearSnippet + ' ' + 'Filtered by ' + criteria
		}
	}

	setDependLinks() {
		let q = ""
		if (this.hasDependLinkTarget) {
			this.dependLinkTargets.forEach(function (target) {
				let url = new URL(target.href)
				for (let p of this.params.entries()) {
					if (p[0] === q) {
						url.searchParams.append(p[0], p[1])
					} else {
						url.searchParams.set(p[0], p[1])
						q = p[0]
					}
				}
				target.href = url.toString()
			}, this)
		}
	}

	displaySpinner() {
		let loadingSnippet = `<i class="fa fa-spinner fa-spin text-muted"></i>`
		this.messageTarget.innerHTML = loadingSnippet
	}

	hideSpinner() {
		this.messageTarget.innerHTML = ''
	}

	clear(event) {
		event.preventDefault()
		$('#forget-modal').modal('hide');
		if (this.hasFilterInputTarget) {
			this.filterInputTargets.forEach(function (target) {
				if (target.name != 'form_set_id') {
					this.params.delete(target.name)

					target.value = ''
					target.dispatchEvent(new Event("change"))

					if (target.tagName === 'SELECT') {
						target.selectedIndex = -1
					}
				}
			}, this)
		}

		this.fetchData(true)
	}

	showFilterForm(event) {
		event.preventDefault()
		$('#filter-modal').modal({
			backdrop: 'static',
			keyboard: false
		});
	}

	showExport(event) {
		event.preventDefault();

		if (this.hasFilterInputTarget) {
			this.filterInputTargets.forEach(function (target) {
				this.params.delete(target.name)
				if (target.tagName === 'SELECT') {
					let selections = target.selectedOptions
					for (let i = 0; i < selections.length; i++) {
						this.params.append(target.name, selections[i].value)
					}
				}
				if (target.tagName === 'INPUT') {
					if (target.type === 'checkbox') {
						if (target.checked) {
							this.params.set(target.name, target.value)
							if (target.name == "full_report") {
								this.params.set(target.name, false);
								console.log(target)
							}
							if (target.name == "report_criteria") {
								this.params.set(target.name, true);
							}
						} else if (target.name == "full_report") {
							this.params.set(target.name, true);
						} else if (target.name == "graphs") {
							this.params.set(target.name, false);
						}
					} else {
						this.params.append(target.name, target.value)
					}
				}
			}, this)

		}
		this.params.delete("mime")
		this.params.set("mime", event.target.id)
		this.setDependLinks()
		$('#export-modal').modal('show');
	}

	reportCriteria(event) {
		this.params.delete('report_criteria')
		this.params.set("report_criteria", $("input[id^='report-criteria']").is(":checked"))
		this.setDependLinks()
	}


	submitFilter(event) {
		$('#filter-modal').modal('hide');
		this.filter()
	}

	showMemorizeModal() {
		event.preventDefault()
		$('#memorize-modal').modal({
			backdrop: 'static',
			keyboard: false
		});
	}

	submitMemorize(event) {
		event.preventDefault();
		if (this.hasFilterInputTarget) {
			this.filterInputTargets.forEach(function (target) {
				this.params.delete(target.name)
				if (target.tagName === 'SELECT') {
					let selections = target.selectedOptions
					for (let i = 0; i < selections.length; i++) {
						this.params.append(target.name, selections[i].value)
					}
				}
				if (target.tagName === 'INPUT') {
					if (target.type === 'checkbox') {
						if (target.checked) {
							this.params.set(target.name, target.value)
							if (target.name == "report_criteria") {
								this.params.set(target.name, true)
							}
							if (target.name == "graphs") {
								this.params.set(target.name, true)
							}
						} else if (target.name == "full_report") {
							this.params.set(target.name, false);
						} else if (target.name == "graphs") {
							this.params.set(target.name, false);
						}
					} else {
						this.params.append(target.name, target.value)
					}
				}
			}, this)
		}
		axios.get('/observe/memorize', {
				params: this.params
			})
			.then(response => {
				window.location = '/observe/summary?select_criteria_set=' + response.data
			});
	}

	showForgetModal() {
		event.preventDefault()
		$('#forget-modal').modal({
			backdrop: 'static',
			keyboard: false
		});
	}

	destroy(event) {
		event.preventDefault();
		$('#forget-modal').modal('hide');
		let url = "/criteria_sets/" + event.target.id + '.json'
		axios.delete(url)
			.then(response => {
				window.location = '/observe/summary'
			});

	}

	submitTimeGrouping(event) {
		event.preventDefault()
		this.params.set("time_grouping", $("#select2-time_grouping-container")[0].title.toLowerCase())
		this.filter()
	}

	disconnect() {}

}