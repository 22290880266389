import { Controller } from 'stimulus'
import axios from 'axios';

export default class CertificateFields extends Controller {
  static targets = ["fieldSource", "sourceAttribute", "manualInputLabel", "manualInputDataType", "staticInputs", "manualInputs", "includeTimeInput"]

  initialize() {
    this.toggleInputs()
    if (this.hasFieldSourceTarget) {
      this.populateSourceAttributes(this.fieldSourceTarget.value)
    }
  }

  fieldSourceSelected(event) {
    this.toggleInputs()
    this.populateSourceAttributes(this.fieldSourceTarget.value)
  }

  sourceAttributeSelected(event) {
    if (this.sourceAttributeTarget.options[this.sourceAttributeTarget.options.selectedIndex].dataset.dataType === 'date') {
      this.includeTimeInputTarget.hidden = false
    } else {
      this.includeTimeInputTarget.hidden = true
    }
  }

  populateSourceAttributes(fieldSourceType) {
    if (this.fieldSourceTarget.value === 'manual_input') return

    axios.get(this.sourceAttributeTarget.dataset.responseUrl, { params: {type: fieldSourceType}})
    .then(response => {

      let values = response.data
      let value = this.sourceAttributeTarget.value
      this.sourceAttributeTarget.innerHTML = ''

      for(var i=0; i < values.length; ++i ){
        let option = document.createElement("option")
        option.value = values[i].key
        option.text = values[i].label
        option.dataset.dataType = values[i].data_type
        if (value === option.value) {
          option.defaultSelected = true
        }
        this.sourceAttributeTarget.append(option)
      }
    })
  }

  toggleInputs() {
    if (this.fieldSourceTarget.value === 'manual_input') {
      this.manualInputSelected()
    } else {
      this.staticSourceSelected()
    }
  }

  manualInputSelected() {
    this.manualInputsTarget.hidden = false
    this.staticInputsTarget.hidden = true
  }

  staticSourceSelected() {
    this.manualInputsTarget.hidden = true
    this.staticInputsTarget.hidden = false
  }
}
