import { Controller } from 'stimulus';

export default class UserAdminAccess extends Controller {
  static targets = ['adminRole', 'adminAccessStatus', 'adminRolesCheckboxes']

  initialize() {
    let validRolePresent = this.adminRoleTargets.some((target) => {
      return target.checked === true;
    });
    if (validRolePresent) {
      this.adminAccessStatusTarget.checked = true;
      this.adminRolesCheckboxesTarget.hidden = false;
    } else {
      this.adminAccessStatusTarget.checked = false;
      this.adminRolesCheckboxesTarget.hidden = true;
    }
  }

  fetchAdminAccessInput() {
    if (this.adminAccessStatusTarget.checked) {
      this.adminRoleTargets.forEach((target) => {
        target.checked = true;
      });
      this.adminRolesCheckboxesTarget.hidden = false;
    } else {
      this.adminRolesCheckboxesTarget.hidden = true;
      this.adminRoleTargets.forEach((target) => {
        target.checked = false;
      });
    }
  }

  fetchRoleInput() {
    let validRolePresent = this.adminRoleTargets.some((target) => {
      return target.checked === true;
    });

    if (this.adminAccessStatusTarget.checked && validRolePresent) {
      this.adminRolesCheckboxesTarget.hidden = false;
    } else {
      this.adminRolesCheckboxesTarget.hidden = true;
      this.adminAccessStatusTarget.checked = false;
    }
  }

  disconnect() {}
}
