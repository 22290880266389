import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = []

  initialize() {
    this.target = document.getElementById(this.element.dataset.target)

    if (this.element.dataset.state == 'collapse') {
        this.target.hidden = true
      }
  }

  toggle(event) {
    this.target.hidden = !this.target.hidden
  }
}
