import { Controller } from 'stimulus';

export default class ImportUser extends Controller {
  static targets = ["presignedUrl"]

  initialize() {
    $(document).on('click', "input:file", function(){
      $('#import-start-button').attr('disabled',false);
    });
    let url = this.presignedUrlTarget.id
    $( document ).on('click', "#import-start-button", function(){
      let field = $('.upload-form').find('input:file');
      let file = field[0].files[0];
      $.ajax({
          xhr: function() {
              var xhr = new window.XMLHttpRequest();
              xhr.upload.addEventListener("progress", function(evt) {
                  if (evt.lengthComputable) {
                      let percentComplete = evt.loaded / evt.total;
                  }
              }, false);

              return xhr;
          },
          type : 'PUT',
          url : url,
          data : file,
          processData: false,  // tell jQuery not to convert to form data
          contentType: file.type,
          success: function(json) {
              console.log('Upload complete!');
              $("#import").click();
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
              console.log('Upload error: ' + XMLHttpRequest.responseText);
          }
      });
  });
  }


}
