import { Controller } from 'stimulus'

 export default class extends Controller {
  initialize() {}

  show(event) {
    event.stopPropagation()

    $(this.element.dataset.targetId).modal('show')
  }
}
