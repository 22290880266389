import { Controller } from 'stimulus'
import $ from 'jquery';

export default class Course extends Controller {

	initialize() {
	  $('#shared-full-width-modal').on('hide.bs.modal', function () {
			// TODO: Investigate. This is reloading the page every time a modal is closed.
			window.location.reload();
			return;
 		});

		$('#shared-full-width-modal').on('shown.bs.modal', function () {
			$('html').css('overflow','hidden');
		}).on('hidden.bs.modal', function() {
			$('html').css('overflow','auto');
		});

		//
		// Used for accordion menus
		//
		$('.accordion').on('click', function(e) {
			$('.glyphicon', this)
				.toggleClass('glyphicon-chevron-right')
				.toggleClass('glyphicon-chevron-down');
		});

		$('.submit-score').click(function(){
			$('#guided-content-player-modal').hide();
		});

		$('#player_menu').on('click', function(){
			$('#player_sidebar_menu').toggle();
		});

		$('#grade-exam-button').on('click', function(){
			$('#exam-submit').click();
		})


		var requiredQuestions = $('.question').length;
		if (requiredQuestions > 0 ) {
			$('#grade-exam-button').hide();
			$('#disabled-grade-button').show();
			$('.exam-choice').on('change', function() {
				var answers = 0;
				$('.question').each(function() {
					if ($(this).find('input[type=radio], input[type=checkbox]').length > 0) {
						var answerCount = $(this).find('input[type=radio]:checked, input[type=checkbox]:checked').length;
					}
					else if ( $(this).find('textarea').length > 0 ) {
						var textCount = 0;
						var shortAnswers = $(this).find('textarea');
						$(shortAnswers).each(function(){
							if (this.value.trim().length > 0) {
								textCount++;
								console.log('success');
							}
						});

						if (textCount > 0) {
							answers++;
						} else {
							answers = 0;
						}
					}
					if ( answerCount > 0) {
						answers++;
					}
				});

				if ( answers == requiredQuestions) {
					$('#grade-exam-button').show();
					$('#disabled-grade-button').hide();
				} else {
					$('#grade-exam-button').hide();
					$('#disabled-grade-button').show();
				}
			});
		}
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
		})
	}

	disconnect() {}
}
