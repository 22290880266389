import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const options = this.options();
    console.log('Select2 Stimulus controller connected.');
    let ajax = null
    if(this.element.dataset.url) {
      ajax = this.setupAjax()
    }
    $(this.element).select2({
      theme: 'bootstrap',
      width: '100%',
      // placeholder: this.element.dataset.placeholder || '',
      ajax: ajax,
      tags: this.element.dataset.tags || false,
      tokenSeparators: [','],
      templateResult: function(item) {
        if (item.html) return $(item.html)

        return item.text
      },
      templateSelection: function(item) {
        if (item.html) return $(item.html)

        return item.text
      },
      closeOnSelect: options.closeOnSelect
    });
    $(this.element).on('select2:select', function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });
  }

  disconnect() {
    $(this.element).select2('destroy')
  }

  setupAjax() {
    return {
      url: this.element.dataset.url,
      delay: this.element.dataset.delay || 250,
      data: function(params) {
        return {
          filter: {
            search: params.term
          }
        }
      },
      processResults: function(data, _params) {
        return {
          results: data.records.map(record => {
            return {
              id: record[this.element.dataset.id || 'id'],
              text: record[this.element.dataset.name || 'name'],
              html: record[this.element.dataset.html || 'html']
            }
          }, this)
        }
      }.bind(this)
    }
  }

  options() {
    const defaultOptions = { closeOnSelect: true };
    const optionsAttr = this.element.getAttribute('data-options');
    const options = optionsAttr ? JSON.parse(optionsAttr) : {};
    return { ...defaultOptions, ...options };
  }
}
