import { Controller } from 'stimulus'

export default class EvaluationExport extends Controller {
  static targets = ["modal"]
  initialize() {}

  show() {
    event.preventDefault()
    $(this.modalTarget).modal({backdrop: 'static', keyboard: false});
  }

  disconnect() {}
}