import { Controller } from 'stimulus'
import tinymce from 'tinymce/tinymce'
import 'tinymce/models/dom/model'
import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver/theme'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
// import 'tinymce/plugins/autosave'
// import 'tinymce/plugins/bbcode'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/code'
// import 'tinymce/plugins/codesample'
// import 'tinymce/plugins/colorpicker'
// import 'tinymce/plugins/contextmenu'
// import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/emoticons'
// import 'tinymce/plugins/fullpage'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
// import 'tinymce/plugins/hr'
import 'tinymce/plugins/image'
// import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/insertdatetime'
// import 'tinymce/plugins/legacyoutput'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
// import 'tinymce/plugins/nonbreaking'
// import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/pagebreak'
// import 'tinymce/plugins/paste'
import 'tinymce/plugins/preview'
// import 'tinymce/plugins/print'
// import 'tinymce/plugins/quickbars'
// import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
// import 'tinymce/plugins/spellchecker'
// import 'tinymce/plugins/tabfocus'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
// import 'tinymce/plugins/textcolor'
// import 'tinymce/plugins/textpattern'
// import 'tinymce/plugins/toc'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'

export default class extends Controller {
  static targets = ['input']

  initialize () {}

  connect () {
    // let config = Object.assign({ target: this.inputTarget }, this.defaults)
    // tinymce.init(config)
    tinymce.init({
      target: this.inputTarget,
      skin: false,
      content_css: false,
      promotion: false,
      branding: false,
      // width: 600,
      // height: 300,
      plugins: [
        'advlist', 'autolink', 'autoresize', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
        'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
        'media', 'table', 'emoticons', 'template', 'help'
      ],
      toolbar: 'styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | preview media fullscreen | ' +
        'forecolor backcolor emoticons | help',
      menubar: 'edit view insert format tools table help'
    })
  }

  disconnect () {
    tinymce.remove()
  }
}
