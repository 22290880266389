import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggleA', 'toggleB']

  toggle(event) {
    this.toggleATargets.forEach(function(target) {
      target.classList.toggle('hidden')
    })

    this.toggleBTargets.forEach(function(target) {
      target.classList.toggle('hidden')
    })
  }
}
