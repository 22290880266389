import { Controller } from 'stimulus'
import $ from 'jquery';
import axios from 'axios';

export default class PublishSections extends Controller {
  static targets = ["digitalAsset", "present"]

  initialize () {
    $(this.digitalAssetTarget).select2({
      theme: 'bootstrap',
      tags: true,
      width: '100%',
      tokenSeparators: [',', ' ']
    });

    if (this.hasPresentTarget) {
      var data = {
        id: this.presentTarget.id,
        text: this.presentTarget.dataset.text,
      };

      var newOption = new Option(data.text, data.id, false, true);
      $('#select2-digital-asset-ajax').append(newOption).trigger('change');
    }

    $(this.digitalAssetTarget).select2({
      width: '100%',
      tags: true,
      ajax: {
        delay: 250,
        url: "/publish/digital_assets/retrieve_available_digital_assets.json",
        dataType: "json",
        type: "GET",
        data: function (params) {
    
          var queryParameters = {
            term: params.term,
            digital_asset_id: document.getElementById('select2-select2-digital-asset-ajax-results').value
          }
          return queryParameters;
        },
        processResults: function (data) {
          return {
              results: $.map(data, function (item) {
                  return {
                      text: item.name,
                      id: item.id
                  }
              })
          };
        },
      },
      minimumInputLength: 3
    });
  }
  
  connect() {}


  disconnect() {}
}
