import {
  Controller
} from 'stimulus';
import axios from 'axios';
import $ from 'jquery';

export default class Infinite extends Controller {
  static targets = ["table", "scroll", "message", "search", "criteria", "header", "filterForm",
    "filterInput", "dependLink", "category", "resellerUserView", "resellerOrgView",
    "assignmentCount", "transcriptReport", "pdHour", "resellerOrgProductFilter", "resellerOrgFilter"
  ]

  initialize() {

    $('#shared-modal').modal('hide');
    this.filterButons();
    this.params = new URLSearchParams()
    this.params.set('offset', this.tableTarget.dataset.offset || 0)
    this.params.set('page', this.tableTarget.dataset.page || 50)
    this.params.set('sort', this.tableTarget.dataset.sort || null)
    this.params.set('order', this.tableTarget.dataset.order || null)

    this.isLoading = false
    this.tableData = []
    this.recordCount = 0
    this.recordTotal = 0
    this.tableBody = this.tableTarget.getElementsByTagName('tbody')[0] || this.tableTarget
    this.searchBounce = _.debounce(this.delayedSearch, 800)
    this.zeroRecordsHtml = this.tableTarget.dataset.zeroRecords || `No records match filter. <a href="#" data-action="click->${this.identifier}#clear">clear filter</a>`
    this.emptyTableHtml = this.tableTarget.dataset.emptyTable || 'Empty Table'
    this.noMoreRecordsHtml = this.tableTarget.dataset.noMoreRecords || 'No More Records'
    this.setActiveTabs()
    this.filter()
  }

  //
  // Public Methods
  //

  filterButons() {};

  search() {
    this.searchBounce()
  }

  setActiveTabs() {
    $(function () {

      $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        sessionStorage.setItem("tab:" + document.URL, $(e.target).attr('id'));
      });

      //go to the latest tab, if it exists:
      var lastTab = sessionStorage.getItem("tab:" + document.URL);
      if (lastTab) {
        $('#' + lastTab).tab('show');
      }

      //when a group is sshown, save it as the active accordion group
      $(".list-group").on('shown.bs.collapse', function (e) {
        var id = $(e.target).attr('id');
        var list_groups = sessionStorage.getItem("bs-list-group:" + document.URL);
        if (list_groups) {
          list_groups = JSON.parse(list_groups);
        } else {
          list_groups = {};
        }
        list_groups[id] = true;
        sessionStorage.setItem("bs-list-group:" + document.URL, JSON.stringify(list_groups));
      });
      $(".list-group").on('hidden.bs.collapse', function (e) {
        var id = $(e.target).attr('id');
        var list_groups = sessionStorage.getItem("bs-list-group:" + document.URL);
        if (list_groups) {
          list_groups = JSON.parse(list_groups);
          delete list_groups[id];
          sessionStorage.setItem("bs-list-group:" + document.URL, JSON.stringify(list_groups));
        }
      });

      var list_groups = sessionStorage.getItem("bs-list-group:" + document.URL);
      if (list_groups) {
        //remove default collapse settings
        $(".list-group .collapse").removeClass('in');
        list_groups = JSON.parse(list_groups);
        for (var id in list_groups) {
          //show the account_last visible group
          $("#" + id).addClass("in");
          $("#chevron-" + id).addClass("glyphicon-menu-down");
        }
      }
    });
  }

  filter() {
    if (this.hasFilterInputTarget) {
      this.filterInputTargets.forEach(function (target) {
        this.params.delete(target.name)
        if (target.tagName === 'SELECT') {
          let selections = target.selectedOptions
          for (let i = 0; i < selections.length; i++) {
            this.params.append(target.name, selections[i].value)
          }
        }
        if (target.tagName === 'INPUT') {
          if (target.type === 'checkbox') {
            if (target.checked) {
              this.params.append(target.name, target.value)
            }
          } else {
            this.params.append(target.name, target.value)
          }
        }
      }, this)
    }
    this.setDependLinks()
    this.fetchData(true)
  }

  category() {}

  submitStatus(event) {
    event.preventDefault()
    this.params.append('status', event.target.attributes[0].value)
    this.setDependLinks()
    this.fetchData(true)
  }


  submitRecordStatus(event) {
    event.preventDefault()
    this.params.delete(event.target.name)
    if (event.target.checked) {
      this.params.set(event.target.name, 'active_and_archived')
    } else {
      this.params.set(event.target.name, "active")
    }
    this.setDependLinks()
    this.fetchData(true)
  }

  submitCategory(event) {
    event.preventDefault()
    this.category()
  }

  submitFilter(event) {
    event.preventDefault()
    $('#filter-modal').modal('hide');
    this.filter()
  }

  showFilterForm(event) {
    event.preventDefault()
    $('#filter-modal').modal({
      backdrop: 'static',
      keyboard: false
    });
  }


  showSoftDelete(event) {
    event.preventDefault()
    $('#soft-delete-modal').modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  showExportModal(event) {
    event.preventDefault()
    $('#export-modal').modal({
      backdrop: 'static',
      keyboard: false
    });
  }


  clear(event) {
    event.preventDefault()
    if (this.hasSearchTarget) {
      this.params.delete('filter[search]')
      this.searchTarget.value = ''
    }
    
    if (this.hasDependLinkTarget) {
      this.dependLinkTargets.forEach(function (target) {
        for (let p of target.href.split('&')) {
          if ( p.includes('filter')) {
            target.href = target.href.replace(p, '');

          }
        }
        let url = new URL(target.href)
        target.href = url.toString()
      }, this)
    }
    if (this.hasFilterInputTarget) {
      this.filterInputTargets.forEach(function (target) {
        this.params.delete(target.name)
        target.value = ''
        target.dispatchEvent(new Event("change"))

        if (target.tagName === 'SELECT') {
          target.selectedIndex = -1
        }
      }, this)
    }
    this.fetchData(true, true)
  }

  sort(event) {
    event.preventDefault()

    let sort_name = event.target.dataset.name
    if (this.params.get('sort') === sort_name && this.params.get('order') === 'ASC') {
      this.params.set('order', 'DESC')
    } else {
      this.params.set('order', 'ASC')
    }
    this.params.set('sort', sort_name)

    this.fetchData(true)
  }

  scroll(event) {
    let scrollHeight = event.target.documentElement.scrollHeight
    let scrollTop = event.target.documentElement.scrollTop
    let clientHeight = event.target.documentElement.clientHeight
    if (scrollHeight - scrollTop - clientHeight <= 0) {
      this.fetchData()
    }
  }

  //
  // Private Methods
  //

  fetchData(clear = false, clearFilters = false) {
    if (this.isLoading) return

    this.isLoading = true

    this.displaySpinner()

    if (clear) {
      this.clearOffset()
    }

    if (clearFilters) this.params.set('filter[clearFilters]', clearFilters)
    
    axios.get(this.tableTarget.dataset.url, {
        params: this.params
      })
      .then(response => {
        this.recordCount = response.data.count
        this.recordTotal = response.data.total
        if (clear) {
          this.clearBody()
        }
        this.load(response.data.records)
        this.hideSpinner()
        this.paging()
        this.displayCriteria(response.data.count, response.data.criteria)
        this.displayNoMoreRecords()
        this.displayEmptyRecords()
        this.decorateHeaders()
        if (this.hasAssignmentCountTarget) {
          $("#assignment_count")[0].innerHTML = response.data.total
        }

        this.isLoading = false
      })
  }

  paging() {
    let page = parseInt(this.params.get('page'))
    let offset = parseInt(this.params.get('offset'))

    if (offset + page > this.recordCount) {
      page = this.recordCount - offset
    }
    this.params.set('offset', offset + page)
  }

  load(items) {}

  clearBody() {}

  clearOffset() {
    this.params.set('offset', 0)
  }

  delayedSearch() {
    if (this.params.get('filter[search]') === this.searchTarget.value) return

    if (this.searchTarget.value) {
      this.params.set('filter[search]', this.searchTarget.value)
    } else {
      this.params.delete('filter[search]')
    }
    this.filter()
  }

  displayCriteria(count, criteria) {
    let records = `Viewing ${new Intl.NumberFormat().format(count)} ${count == '1' ? "record" : "records"}`
    
    if (criteria === "") {
      this.criteriaTarget.innerHTML = records
    } else {
      let clearSnippet = `
        <a href="#" data-action="click->${this.scope.identifier}#clear">
          <i class="fa fa-times-circle text-danger"></i>
        </a>
      `
      this.criteriaTarget.innerHTML = clearSnippet + ' ' + records + ' filtered by ' + criteria
    }
  }

  displaySpinner() {
    let loadingSnippet = `<i class="fa fa-spinner fa-spin text-muted"></i>`
    this.messageTarget.innerHTML = loadingSnippet
  }

  hideSpinner() {
    this.messageTarget.innerHTML = ''
  }

  displayNoMoreRecords() {
    let page = parseInt(this.params.get('page'))
    let offset = parseInt(this.params.get('offset'))
    if ((offset + page) >= this.recordCount) {
      this.messageTarget.innerHTML = this.noMoreRecordsHtml
    }
  }

  displayEmptyRecords() {
    if (this.recordTotal === 0) {
      this.messageTarget.innerHTML = this.emptyTableHtml
    } else if (this.recordCount === 0) {
      this.messageTarget.innerHTML = this.zeroRecordsHtml
    }
  }

  decorateHeaders() {
    this.headerTargets.forEach(function (header) {
      var child = header.lastElementChild;
      while (child) {
        header.removeChild(child);
        child = header.lastElementChild;
      }
      if (header.dataset.name == this.params.get('sort')) {
        let upNode = document.createElement("i")
        if (this.params.get('order') === 'ASC') {
          upNode.className = 'fa fa-sort-up text-muted pl-1'
        } else {
          upNode.className = 'fa fa-sort-down text-muted pl-1'
        }
        header.appendChild(upNode)
      }
    }, this)
  }
  // Looks for any button selections -- Bulk and Export
  setDependLinks() {
    let q = ""


    if (this.hasDependLinkTarget) {
      this.dependLinkTargets.forEach(function (target) {
        for (let params of target.href.split('&')) {
          if ( params.includes('filter')) {
            target.href = target.href.replace(params, '');
          }
        }
        let url = new URL(target.href)
        for (let p of this.params.entries()) {

          if (p[0] === q) {
            url.searchParams.append(p[0], p[1])
          } else {
            url.searchParams.set(p[0], p[1])
            q = p[0]
          }
        }
        target.href = url.toString()
      }, this)
    }
  }

  submitCategory(event) {
    event.preventDefault();
    this.params.delete('filter[category_ids]')
    let categoryIds = []
    this.categoryTargets.forEach(function (target) {
      if (target.checked) {
        categoryIds.push(target.value)
      }
    }, this)
    this.params.append('filter[category_ids]', categoryIds)
    this.filter()
  }
}

