import LoadSummaryReport from "./load_summary_report_controller";
import axios from 'axios';

export default class SectionReport extends LoadSummaryReport {
  static targets = ["section", "spinner"]

  initialize() {
    console.log('report')
    let element = document.getElementById(this.sectionTarget.id);
    axios.get(this.sectionTarget.dataset.url)
   .then(response => {
     element.insertAdjacentHTML('afterend', response.data)
     if ($('#full-report-toggle')[0].checked === false) {
      $('.condensedTable').hide();
      $("#graphs").show();
      $('.full-report-table').show();
    } else {
      $('.condensedTable').show();
      $("#graphs").hide();
      $('.full-report-table').hide();
    }
     $('[data-toggle="tooltip"]').tooltip();
     $(this.spinnerTarget).hide();
     this.drawGraphs();
     this.sparkLineGraphs();
     this.scoreGraphs();
   })
  }
}