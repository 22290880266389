import Infinite from "./infinite_controller";

export default class InfiniteProduct extends Infinite {

  load(items) {
    let parentDiv = document.createElement("div")
    items.forEach(function(item, key) {
      let div = document.createElement("div")

      div.className = "col-xs-6"
      Object.values(item).forEach(function(value) {
        div.innerHTML = value
        parentDiv.append(div)
      })
    }, this)
    this.tableTarget.append(parentDiv)
  }

  clearBody() {
    this.tableBody.innerHTML = ''
  }

  showProductFilterForm(event) {
    event.preventDefault()
    $('#product-filter-modal').modal({backdrop: 'static', keyboard: false});
  }

  submitFilter(event) {
    event.preventDefault()

    $('#product-filter-modal').modal('hide');

    this.filter()
  }

}
