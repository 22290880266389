import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = []

  toggle(event) {
    let chevronRight = 'fa-chevron-right'
    let chevronDown = 'fa-chevron-down'

    if (this.element.classList.contains(chevronRight)) {
      this.element.classList.remove(chevronRight)
      this.element.classList.add(chevronDown)
    }
    else if (this.element.classList.contains(chevronDown)) {
      this.element.classList.remove(chevronDown)
      this.element.classList.add(chevronRight)
    }
  }
}
