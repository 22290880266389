import Infinite from "./infinite_controller";

export default class InfiniteTable extends Infinite {

  load(items) {
    items.forEach(function(item, key) {
      let row = this.tableBody.insertRow()
      row.id = this.tableBody.id + "-" + item.id;
      Object.values(item).forEach(function(value) {
        let cell = row.insertCell()
        cell.innerHTML = value
      })
    }, this)
  }

  clearBody() {
    this.tableBody.innerHTML = ''
  }

  filterButons() {
    let orgFilter = document.getElementById('reseller-org-filter');
    let orgProductFilter = document.getElementById('reseller-product-org-filter');

    if (orgFilter) {
      $(orgFilter).select2({
        width: '100%',
        tags: true,
        ajax: {
          delay: 250,
          url: "/reseller/reports_available_organizations.json",
          dataType: "json",
          type: "GET",
          data: function (params) {

            var queryParameters = {
              term: params.term
            }
            return queryParameters;
          },
          processResults: function (data) {
            return {
                results: $.map(data, function (item) {
                    return {
                        text: item.name,
                        id: item.id
                    }
                })
            };
          },
        },
        minimumInputLength: 3
      });
    }
    if (orgProductFilter) {
      $(orgProductFilter).select2({
        width: '100%',
        tags: true,
        ajax: {
          delay: 250,
          url: "/reseller/reports_available_reseller_licenses.json",
          dataType: "json",
          type: "GET",
          data: function (params) {
            var queryParameters = {
              term: params.term
            }
            return queryParameters;
          },
          processResults: function (data) {
            return {
                results: $.map(data, function (item) {
                    return {
                        text: item.name,
                        id: item.id
                    }
                })
            };
          },
        },
        minimumInputLength: 3
      });
    }
  }

  newRatingScaleModal(event) {
    event.preventDefault()
    $('#new-rating-scale-modal').modal({backdrop: 'static', keyboard: false});
  }

  newEvaluationPeriodModal(event) {
    event.preventDefault()
    $('#new-evaluation-period-modal').modal({backdrop: 'static', keyboard: false});
  }

}
