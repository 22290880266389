import { Controller } from 'stimulus'
import axios from 'axios'

export default class NavMenu extends Controller {
  static targets = []

  initialize () {}

  connect() {}

  clearNotifications(event) {
    axios.get("/message_items/mark_user_read.json")
    $("#message-icon").attr('class', 'icon-envelope-open');
    $("#badge-counter").text('0');

  }


  disconnect() {}
}
