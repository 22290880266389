import { Controller } from 'stimulus'
import $ from 'jquery';

export default class SuspendUserStatus extends Controller {
  static targets = ["status", "suspend"]

  initialize () {
    let suspend = this.suspendTarget;
    $(suspend).hide();
    if (this.hasStatusTarget) {
      $(this.statusTarget).on('change', function() {
        if (this.value === "active") {
          $(suspend).show();
        } else {
          $(suspend).hide();
        }
      })
    }

  }
  connect() {}


  disconnect() {}
}