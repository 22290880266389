import Infinite from "./infinite_controller";

export default class InfiniteContent extends Infinite {

  load(items) {
    let parentDiv = document.createElement("div")
    parentDiv.className = "col-xs-12"
    items.forEach(function (item, key) {
      let div = document.createElement("div")
      Object.values(item).forEach(function (value) {
        div.innerHTML = value
        parentDiv.append(div)
      })
    }, this)
    this.tableTarget.append(parentDiv)
  }

  category() {
    let categoryIds = []

    for (let i = 0; i < $("input[id^='category_ids']").length; i++) {
      if ($("input[id^='category_ids']")[i].checked === true) {
        categoryIds.push($("input[id^='category_ids']")[i].value)
        $("input[id^='category_ids']")[i].checked = true
      } else {
        $("input[id^='category_ids']")[i].checked = false
      }
    }

    this.params.set('category_ids', categoryIds)
    this.setDependLinks()
    this.fetchData(true)
  }

  clearBody() {
    this.tableBody.innerHTML = ''
  }

  clear(event) {
    event.preventDefault()

    this.params.delete('status')
    if (this.hasSearchTarget) {
      this.params.delete('filter[search]')
      this.searchTarget.value = ''
    }

    this.params.delete('filter[category_ids]')
    for (let i = 0; i < $("input[name^='filter[category_ids][]']").length; i++) {
      if ($("input[name^='filter[category_ids][]']")[i].checked === true) {
        $("input[name^='filter[category_ids][]']")[i].checked = false
      }
    }

    if (this.hasFilterInputTarget) {
      this.filterInputTargets.forEach(function (target) {
        this.params.delete(target.name)
        target.value = ''
        target.dispatchEvent(new Event("change"))

        if (target.tagName === 'SELECT') {
          target.selectedIndex = -1
        }
      }, this)
    }

    this.fetchData(true)
  }
}