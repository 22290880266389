import { Controller } from 'stimulus'
import $ from 'jquery';

export default class NavItem extends Controller {
  static targets = ["submenu"]

  initialize () {
    $('#submenu').hide()
  }
  
  connect() {}

  expandMenu(event) {
    event.preventDefault()
    $(this.submenuTarget).toggle()
  }


  disconnect() {}
}
