require('moment')

import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'
import moment from 'moment'

export default class DateRangePicker extends Controller {
  static targets = ["dateInput", "startDate", "endDate", "apply", "startDateError", "startDateInput", "endDateInput"]

  initialize() {

    this.maxDays = parseInt(this.element.dataset.maxDays)

    this.startDateInput = this.startDateTarget
    if (this.hasStartDateInputTarget) {
      this.startDateInput = this.startDateInputTarget
    }

    this.endDateInput = this.endDateTarget
    if (this.hasEndDateInputTarget) {
      this.endDateInput = this.endDateInputTarget
    }

    let startDateDefaultValue = ""
    let endDateDefaultValue = ""

    if(this.startDateInput && this.startDateInput.value) {
      startDateDefaultValue = new Date(this.startDateInput.value)
    }
    if(this.endDateInput && this.endDateInput.value) {
      endDateDefaultValue = new Date(this.endDateInput.value)
    }

    this.startPicker = flatpickr(this.startDateTarget, { dateFormat: 'Y-m-d H:i', defaultDate: startDateDefaultValue } )
    this.endPicker = flatpickr(this.endDateTarget, { dateFormat: 'Y-m-d H:i', defaultDate: endDateDefaultValue } )
    this.initializeDateRange()
  }

  toggleStart(event) {
    event.preventDefault()

    this.startPicker.toggle()
  }

  toggleEnd(event) {
    event.preventDefault()

    this.endPicker.toggle()
  }

  refreshEndDateCalendar(event) {
    this.endPicker.set("maxDate", moment(this.startDateInput.value).add(this.maxDays, 'days').toDate())

    this.endPicker.set("minDate", moment(this.startDateInput.value).toDate())
  }

  setDate(event) {
    event.preventDefault();

    this.startDateInput.value = event.target.dataset.startDate;
    this.endDateInput.value = event.target.dataset.endDate;

    let startDate = moment(this.startDateInput.value);
    let endDate = moment(this.endDateInput.value);

    this.startPicker.setDate(new Date(startDate));
    this.endPicker.setDate(new Date(endDate));

    this.applyTarget.dispatchEvent(new Event("click"));
  }

  checkChange(event) {
    event.preventDefault()

    this.applyTarget.disabled = false
    this.startDateErrorTarget.hidden = true

    if(!this.startDateInput.value || !this.endDateInput.value) {
      return
    }

    if(Date.parse(this.startDateInput.value) < Date.parse(this.endDateInput.value)) return

    this.startDateErrorTarget.hidden = false
    this.applyTarget.disabled = true
  }

  getDateRange() {
    let rangeText = 'All Dates'
    let startDate = moment(this.startDateInput.value)
    let endDate = moment(this.endDateInput.value)
    const startDateValid = startDate.isValid();
    const endDateValid = endDate.isValid();

    if (startDateValid && endDateValid) {
      rangeText = `${startDate.format('L')} to ${endDate.format('L')}`
    } else if (startDateValid && !endDateValid) {
      rangeText = `Starting ${startDate.format('L')}`
    } else if (!startDateValid && endDate.isValid()) {
      rangeText = `Until ${endDate.format('L')}`
    }

    return rangeText
  }

  initializeDateRange() {
    const rangeText = this.getDateRange();
    this.dateInputTarget.innerHTML = `<i class="far fa-calendar-alt text-primary pr-1"></i> ${rangeText}`
  }

  apply(event) {
    event.preventDefault()

    const rangeText = this.getDateRange();

    this.dateInputTarget.innerHTML = `<i class="far fa-calendar-alt text-primary pr-1"></i> ${rangeText}`
    $(this.dateInputTarget).dropdown('toggle')
  }

  // submitForm(event) {
  //   event.preventDefault()

  //   let rangeText = 'All Dates'

  //   if (this.startDateInput.value && this.endDateInput.value) {
  //     rangeText = `${this.startDateInput.value} to ${this.endDateInput.value}`
  //   } else if (this.startDateInput.value) {
  //     rangeText = `Starting ${this.startDateInput.value}`
  //   } else if (this.endDateInput.value) {
  //     rangeText = `Until ${this.endDateInput.value}`
  //   }
  //   this.dateInputTarget.innerHTML = `<i class="far fa-calendar-alt text-primary pr-1"></i> ${rangeText}`
  //   $(this.element).closest('form').submit()
  // }

  disconnect() {}
}
