import Infinite from "./infinite_controller";

export default class InfiniteCard extends Infinite {

  load(items) {
    let parentDiv = document.createElement("div")
    items.forEach(function(item, key) {
      let div = document.createElement("div")

      div.className = "col-xs-6 col-sm-4 col-md-4 col-lg-3"
      Object.values(item).forEach(function(value) {
        div.innerHTML = value
        parentDiv.append(div)
      })
    }, this)
    this.tableTarget.append(parentDiv)
  }

  clearBody() {
    this.tableBody.innerHTML = ''
  }

}
