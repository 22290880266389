import { Controller } from 'stimulus'
import $ from 'jquery';

export default class Global extends Controller {
  static targets = ["submenu"]

  navToggle(event) {
    event.preventDefault();
    this.submenuTarget.show();
  }

}
