import { Controller } from 'stimulus';
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["close", "error", "message"]
  static values = {job: String}

  initialize() {
    this.close_url = null

    $('#progress-modal').modal('show');

    this.subscription = consumer.subscriptions.create({
      channel: "ProgressChannel",
      job_id: this.jobValue
      }, {
      connected: () => {
        // Called when the subscription is ready for use on the server
        console.log(`Connected to the job progress channel with job id: ${this.jobValue}`);
      },

      disconnected: () => {
        // Called when the subscription has been terminated by the server
      },

      received: (data) => {
        // Called when there's incoming data on the websocket for this channel
        console.log(`Received: ${data.status} ${data.description} ${data.option || ''}`)
        this.messageTarget.innerHTML = data.description;
        if (data.status === 'done') {
          if (data.option && data.option.url != null && !data.option.keep_open) {
            window.location.assign(data.option.url)
          }
          if (data.option && data.option.keep_open) {
            this.close_url = data.option.url
            this.closeTarget.hidden = false
          } else {
            $('#progress-modal').modal('hide');
          }
          this.subscription.unsubscribe();
        } else if (data.status === 'error') {
          this.closeTarget.hidden = false
          this.errorTarget.innerHTML = data.option.error
          this.errorTarget.hidden = false
          this.subscription.unsubscribe();
        } else if (data.status === 'reload') {
          window.location.reload()
        }
      }
    })
  }

  close(event) {
    event.preventDefault()

    if (this.close_url) window.location.assign(this.close_url)
  }
}

