import { Controller } from 'stimulus'
import axios from 'axios'
import Infinitev2 from './infinitev2_controller'

export default class extends Infinitev2 {
  loadItems(items) {
    items.forEach(function(item, key) {
      let li = document.createElement('li');
      li.className = this.containerBodyTarget.dataset.className || "list-group-item"
      li.innerHTML = item.content;
      this.containerBodyTarget.appendChild(li)
    }, this)
  }
}
