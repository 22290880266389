import Highcharts from "./high_charts_controller";
import axios from 'axios';

export default class AdminDashboard extends Highcharts {
  static targets = ["dashboardSelector", "dependLink", "table", "message", "filterInput"]

  initialize() {
    $(this.dashboardSelectorTarget).select2({
      theme: 'bootstrap',
      tags: true,
      tokenSeparators: [',', ' ']
    });
    $(this.dashboardSelectorTarget).on('select2:select', function () {
      let event = new Event('change', {
        bubbles: true
      })
      this.dispatchEvent(event);
    });
    this.params = new URLSearchParams()
    this.isLoading = false
    this.tableData = []
    this.tableBody = this.tableTarget.getElementsByTagName('tbody')[0] || this.tableTarget
    this.filter()
  }

  connect() {}


  filter() {
    if (this.hasFilterInputTarget) {
      this.filterInputTargets.forEach(function (target) {
        this.params.delete(target.name)
        if (target.tagName === 'SELECT') {
          let selections = target.selectedOptions
          for (let i = 0; i < selections.length; i++) {
            this.params.append(target.name, selections[i].value)
          }
        }
        if (target.tagName === 'INPUT') {
          if (target.type === 'checkbox') {
            if (target.checked) {
              this.params.append(target.name, target.value)
            }
          } else {
            this.params.append(target.name, target.value)
          }
        }
      }, this)
    }
    this.setDependLinks()
    this.fetchData(true)
  }


  fetchData(clear = false) {
    if (this.isLoading) return

    this.isLoading = true

    this.displaySpinner()
    axios.get(this.tableTarget.dataset.url, {
        params: this.params
      })
      .then(response => {
        this.load(response.data.records.menu)
        this.hideSpinner()
        this.isLoading = false
      })
  }

  setDependLinks() {
    let q = ""
    if (this.hasDependLinkTarget) {
      this.dependLinkTargets.forEach(function (target) {
        let url = new URL(target.href)
        for (let p of this.params.entries()) {
          if (p[0] === q) {
            url.searchParams.append(p[0], p[1])
          } else {
            url.searchParams.set(p[0], p[1])
            q = p[0]
          }
        }
        target.href = url.toString()
      }, this)
    }
  }

  displaySpinner() {
    let loadingSnippet = `<i class="fa fa-spinner fa-spin text-muted"></i>`
    this.messageTarget.innerHTML = loadingSnippet
  }

  hideSpinner() {
    this.messageTarget.innerHTML = ''
  }

  load(menu) {
    this.tableTarget.innerHTML = ""
    let parentDiv = document.createElement("div")
    parentDiv.innerHTML = menu
    this.tableTarget.append(parentDiv)
  }

  submitFilter(event) {
    event.preventDefault()
    this.filter()
  }

  disconnect() {
    $(this.dashboardSelectorTarget).select2('destroy')
  }
}