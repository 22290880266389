import { Controller } from 'stimulus';
import $ from 'jquery';

export default class ResellerSso extends Controller {
  initialize() {
    if ($('#reseller-enable-sso').is(":checked")) {
      $('#reseller-sso-provider').show();
      }
    else {
      $('#reseller-sso-provider').hide();
    }

    $('#reseller-enable-sso').on('switchChange.bootstrapSwitch', function ()  { 
      if ($('#reseller-enable-sso').is(":checked")) {
        $('#reseller-sso-provider').show();
        $('#reseller-saml-fields').hide();
      }
      else  {
        $('#reseller-sso-provider').hide();
        $('#reseller-enable-sso').val(false);
      }
    });


    $('#provider_type').change(function(){
      if ($(this).val() == "SamlProvider" ){
        $('#reseller-saml-fields').show();
      }
      else {
        $('#reseller-saml-fields').hide();
      }
    });


    if ( $('#provider_type').val() != "SamlProvider") {
      $('#reseller-saml-fields').hide();
    }
  }
}