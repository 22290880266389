import { Controller } from 'stimulus';
import axios from 'axios';
import { forEach } from 'lodash';

export default class extends Controller {
  static targets = ["container", "containerBody", "scroll", "message", "search", "criteria", "header", "filterForm", "filterInput", "dependLink", "refresh"]

  initialize() {
    this.params = new URLSearchParams()
    this.params.set('offset', this.containerTarget.dataset.offset || 0)
    this.params.set('page', this.containerTarget.dataset.page || 50)
    this.params.set('sort', this.containerTarget.dataset.sort || '')
    this.params.set('order', this.containerTarget.dataset.order || '')

    this.isLoading = false
    this.containerData = []
    this.recordCount = 0
    this.recordTotal = 0
    this.searchBounce = _.debounce(this.delayedSearch, 800)
    this.zeroRecordsHtml = this.containerTarget.dataset.zeroRecords || `No records match filter. <a href="#" data-action="click->${this.identifier}#clear">clear filter</a>`
    this.emptyTableHtml = this.containerTarget.dataset.emptyTable || 'Empty Table'
    this.noMoreRecordsHtml = this.containerTarget.dataset.noMoreRecords || 'No More Records'
    this.clearClass = this.element.dataset.clearClass || 'fa fa-times-circle text-danger'
    this.sortUpClass = this.element.dataset.sortUpClass || 'fa fa-sort-up text-muted pl-1'
    this.sortDownClass = this.element.dataset.sortDownClass || 'fa fa-sort-down text-muted pl-1'
    this.spinnerClass = this.element.dataset.spinnerClass || 'fa fa-spinner fa-spin text-muted'
    this.searchInputName = 'filter[search]'
    if (this.hasSearchTarget && this.searchTarget.name) {
      this.searchInputName = this.searchTarget.name
    }

    if (this.hasMessageTarget) {
      this.messageTarget.hidden = true
    }
    this.filter()
    this.observeContainerBody()
  }

  //
  // Public Methods
  // TODO: in stage/production the search field doesn't send all characters to server
  // for instance 'smith' might show up as 'sm' in the criteria so the criteria message
  // says 'sm' but the search bars says 'smith'.
  //

  search() {
    this.searchBounce()
  }

  targetFilter(e) {
    e.preventDefault()

    let target = e.target
    if (target.dataset.filter) {
      this.params.delete(target.dataset.name || target.name)
      let selections = JSON.parse(target.dataset.filter)
      for (let i=0; i< selections.length; i++) {
        this.params.append(target.dataset.name || target.name, selections[i])
      }
      this.findAndReplace(target.dataset.name || target.name, selections)
      this.setDependLinks()
      this.fetchData(true)
    }
  }

  // Use targets to create parameters
  filter() {
    if (this.hasFilterInputTarget) {
      this.filterInputTargets.forEach(function(target) {
        this.params.delete(target.name)

        if(target.tagName === 'SELECT') {
          let selections = target.selectedOptions
          for (let i=0; i< selections.length; i++) {
            this.params.append(target.name, selections[i].value)
          }
        }
        if(target.tagName === 'INPUT') {
          if(target.type === 'checkbox') {
            if (target.checked) {
              this.params.append(target.name, target.value)
            }
          } else {
            this.params.append(target.name, target.value)
          }
        }
      }, this)
    }

    this.setDependLinks()
    this.fetchData(true)
  }

  submitFilter(e) {
    e.preventDefault()

    $('#filter-modal').modal('hide');

    this.filter()
  }

  showFilterForm(e) {
    e.preventDefault()

    $('#filter-modal').modal({backdrop: 'static', keyboard: false});
  }

  clear(e) {
    e.preventDefault()

    this.clearAll()

    this.setDependLinks()
    this.fetchData(true)
  }

  sort(e) {
    e.preventDefault()

    let sort_name = e.target.dataset.name
    if (this.params.get('sort') === sort_name && this.params.get('order') === 'ASC') {
      this.params.set('order', 'DESC')
    } else {
      this.params.set('order', 'ASC')
    }
    this.params.set('sort', sort_name)

    this.setDependLinks()
    this.fetchData(true)
  }

  scroll(e) {
    let target = e.target.documentElement || e.target
    let scrollHeight = Math.round(target.scrollHeight)
    let scrollTop = Math.round(target.scrollTop)
    let clientHeight = target.clientHeight
    // console.log(`scrollHeight: ${scrollHeight}, scrollTop: ${scrollTop}, calculated: ${scrollHeight - scrollTop}, clientHeight: ${clientHeight}, equal? ${scrollHeight - scrollTop === clientHeight}`)
    if (scrollHeight - scrollTop === clientHeight) {
      console.log("hit bottom!");
      this.fetchData()
    }
  }

  //
  // Private Methods
  //

  fetchData(clear = false) {
    if (this.isLoading) return

    this.isLoading = true

    this.displaySpinner()

    if (clear) {
      this.clearOffset()
    }

    axios.get(this.containerTarget.dataset.url, { params: this.params })
    .then(response => {
      this.recordCount = response.data.count
      this.recordTotal = response.data.total
      if (clear) {
        this.clearContainer()
      }
      this.loadItems(response.data.records)
      this.hideSpinner()
      this.paging()
      this.displayCriteria(response.data.count, response.data.criteria)
      this.displayNoMoreRecords()
      this.displayEmptyRecords()
      this.decorateHeaders()

      this.isLoading = false
    })
  }

  paging() {
    let page = parseInt(this.params.get('page') || "0")
    let offset = parseInt(this.params.get('offset') || "50")
    if (offset + page > this.recordCount) {
      page = this.recordCount - offset
    }
    this.params.set('offset', offset + page)
  }

  loadItems(items) {}

  clearContainer() {
    this.containerBodyTarget.innerHTML = ''
  }

  clearOffset() {
    this.params.set('offset', 0)
  }

  delayedSearch() {
    console.log('searchDebounce')
    if (this.params.get(this.searchInputName) === this.searchTarget.value) return

    if (this.searchTarget.value) {
      this.params.set(this.searchInputName, this.searchTarget.value)
    } else {
      this.params.delete(this.searchInputName)
    }
    this.filter()
  }

  displayCriteria(count, criteria) {
    let records = `Viewing ${new Intl.NumberFormat().format(count)} ${count == '1' ? "record" : "records"}`
    if (criteria === "") {
      this.criteriaTarget.innerHTML = records
    } else {
      let clearSnippet = `
        <a href="#" data-action="click->${this.scope.identifier}#clear">
          <i class="${this.clearClass}"></i>
        </a>
      `
      this.criteriaTarget.innerHTML = clearSnippet + ' ' + records + ' filtered by ' + criteria
    }
  }

  displaySpinner() {
    let loadingSnippet = `<i class="${this.spinnerClass}"></i>`
    this.messageTarget.innerHTML = loadingSnippet
  }

  hideSpinner() {
    this.messageTarget.innerHTML = ''
  }

  displayNoMoreRecords() {
    if ((this.params.get('offset') + this.params.page) >= this.recordCount) {
      this.messageTarget.hidden = false
      this.messageTarget.innerHTML = this.noMoreRecordsHtml
    }
  }

  displayEmptyRecords() {
    if (this.recordTotal === 0) {
      this.messageTarget.hidden = false
      this.messageTarget.innerHTML = this.emptyTableHtml
    } else if (this.recordCount === 0) {
      this.messageTarget.hidden = false
      this.messageTarget.innerHTML = this.zeroRecordsHtml
    }
  }

  decorateHeaders() {
    this.headerTargets.forEach(function(header) {
      var child = header.lastElementChild;
      while (child) {
          header.removeChild(child);
          child = header.lastElementChild;
      }
      if(header.dataset.name == this.params.get('sort')) {
        let upNode = document.createElement("i")
        if (this.params.get('order') === 'ASC') {
          upNode.className = this.sortUpClass
        } else {
          upNode.className = this.sortDownClass
        }
        header.appendChild(upNode)
      }
    }, this)
  }

  setDependLinks() {
    if (this.hasDependLinkTarget) {
      this.dependLinkTargets.forEach(function(target) {
        let url = null
        if (target.hasAttribute('href')) {
          url = new URL(target.href, target.baseURI)
        }
        if(target.hasAttribute('data-url')) {
          url = new URL(target.dataset.url, target.baseURI)
        }
        url.search = ''
        for (let p of this.params.entries()) {
          url.searchParams.append(p[0], p[1])
        }
        if (target.hasAttribute('href')) {
          target.href = url.toString()
        }
        if(target.hasAttribute('data-url')) {
          target.dataset.url = url.toString()
        }
      }, this)
    }
  }

  clearAll() {
    const pArray = Array.from(this.params);
    for(const param of pArray) {
      if (param[0].startsWith('filter')) {
        this.params.delete(param[0])
      }
    }

    if (this.hasSearchTarget) {
      this.params.delete(this.searchInputName)
      this.searchTarget.value = ''
    }

    if (this.hasFilterInputTarget) {
      this.filterInputTargets.forEach(function(target) {
        this.clearFilterInputTarget(target)
      }, this)
    }
  }

  clearFilterInputTarget(target) {
    if(target.tagName === 'SELECT' || target.tagName === 'INPUT') {
      if(target.type === 'checkbox') {
        target.checked = false
      } else {
        target.value = ''
      }
      target.dispatchEvent(new Event("change"))
    }

    if(target.tagName === 'SELECT') {
      target.selectedIndex = -1
    }
  }

  findAndReplace(targetName, values) {
    if (this.hasFilterInputTarget) {
      this.filterInputTargets.forEach(function(target) {
        if (target.name === targetName) {
          this.replaceFilterInputTarget(target, values)
        }
      }, this)
    }
  }

  replaceFilterInputTarget(target, values) {
    if(target.tagName === 'SELECT' || target.tagName === 'INPUT') {
      target.value = values
      target.dispatchEvent(new Event("change"))
    }
  }

  observeContainerBody() {
    const target = this.containerBodyTarget;
    const observer = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList' && this.hasRefreshTarget && !this.isLoading) {
          console.log('refresh called')
          this.filter();
        }
      });
    });

    observer.observe(target, { childList: true });
  }
}
