import {
  Controller
} from 'stimulus';
import axios from 'axios';
import qq from "@mersoft-corp/s3.fine-uploader/s3.fine-uploader";
import $ from 'jquery';

export default class Evaluation extends Controller {
  static targets = ["dependLink", "form", "attachmentUploader"]

  initialize() {
    $('.mt-radio').click(function () {
      $('.answer-value', this)[0].checked = true;
      $('.answer-value', this)[1].checked = true;
      $('#observation-form').append(
        $("<input>", {
          type: "hidden",
          name: this.children[1].name,
          value: this.children[1].value
        })
      );
    });

    this.setRequiredCourseUser()
    this.setCounterQuestions()
    this.setAnswerValues()

  }
  setCounterQuestions() {}

  setRequiredCourseUser() {}

  setAnswerValues() {}

  setDependLinks() {
    let q = ""
    if (this.hasDependLinkTarget) {
      this.dependLinkTargets.forEach(function (target) {
        let url = new URL(target.href)
        for (let p of this.params.entries()) {
          if (p[0] === q) {
            url.searchParams.append(p[0], p[1])
          } else {
            url.searchParams.set(p[0], p[1])
            q = p[0]
          }
        }
        target.href = url.toString()
      }, this)
    }
  }

  connect() {}

  showAttachModal(event) {
    console.log(event.target)
    event.preventDefault()
    let modal = "#attach-modal-" + event.target.id
    let attachmentCounter = "#attachment-count-" + event.target.id
    let uploader = new qq.s3.FineUploader({
      element: document.getElementById("attachment-uploader-" + event.target.id),
      chunking: {
        enabled: false
      },
      request: {
        endpoint: "https://" + event.target.dataset.bucket + ".s3.amazonaws.com",
        accessKey: event.target.dataset.access
      },
      deleteFile: {
        enabled: true,
        endpoint: '/fine_uploader/uploaded_files',
        customHeaders: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
        },
        params: {
          evaluation_id: event.target.dataset.observationId,
          observation_temp_id: event.target.dataset.tempId
        }
      },
      session: {
        endpoint: '/fine_uploader/uploaded_files',
        params: {
          evaluation_id: event.target.dataset.observationId,
          observation_temp_id: event.target.dataset.tempId,
          form_question_id: event.target.id,
          evaluation_attachment_ids: event.target.dataset.attachmentIds
        },
        customHeaders: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
        }
      },
      objectProperties: {
        key: function (fileID) {
          return event.target.dataset.key + this.getUuid(fileID) + '/' + this.getName(fileID);
        }
      },
      signature: {
        endpoint: '/fine_uploader/aws_signatures',
        customHeaders: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
        }
      },
      callbacks: {
        onComplete: function () {
          $(attachmentCounter).html(this.getNetUploads());
        },
        onDeleteComplete: function () {
          $(attachmentCounter).html(this.getNetUploads());
        },
        onSessionRequestComplete: function (response) {
          $(attachmentCounter).html(response.length);
        }
      }
    });
    console.log(uploader.getNetUploads())
    $(attachmentCounter).html(uploader.getNetUploads());
    $(modal).modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  newFeedbackModall(event) {
    let modal = "#new-feedback-" + event.target.dataset.formQuestionId

    $(modal).modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  showCommentModal(event) {
    event.preventDefault()
    let modal = "#comments-" + event.target.id
    $(modal).modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  showConfirmModal(event) {
    event.preventDefault()
    $("#confirm-modal").modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  confirm(event) {}



  disconnect() {}
}