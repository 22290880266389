import { Controller } from 'stimulus'
import axios from 'axios';
import $ from 'jquery';

export default class ToggleSlow extends Controller {
  static targets = ["field", "toggle", "secondField", "secondToggle", "products", "bundleProducts", "selectBundleProducts"]

  initialize () {
    if (this.hasProductsTarget) {
      $(this.productsTarget).select2({
        theme: 'bootstrap',
        width: '100%'
      })

      $(this.productsTarget).on('select2:select', function () {
        let event = new Event('event', { bubbles: true })
        this.dispatchEvent(event);
      });
      this.fetchProducts()
    }

    if (this.hasFieldTarget) {
      let target = this.toggleTarget
      $(this.fieldTarget).bootstrapSwitch({})
      $(this.fieldTarget).on('switchChange.bootstrapSwitch', function (event, state) {
        $(target).toggle('slow')
      });
    }

    if (this.hasBundleProductsTarget) {
      let target = this.selectBundleProductsTarget
      $(this.bundleProductsTarget).bootstrapSwitch({})
      $(this.bundleProductsTarget).on('switchChange.bootstrapSwitch', function (event, state) {
        $(target).toggle('slow')
      });
    }



    if (this.hasSecondFieldTarget) {
      let target = this.secondToggleTarget
      $(this.secondFieldTarget).bootstrapSwitch({})
      $(this.secondFieldTarget).on('switchChange.bootstrapSwitch', function (event, state) {
        $(target).toggle('slow')
      });
    }
  }

  connect() {}

  fetchProducts () {
    axios.get("/product_licenses/retrieve_available_product_licenses.json")
    .then(response => {
      let values = response.data
      for(var i=0; i < values.length; ++i ){
          let option = document.createElement("option")
          option.value = values[i].id
          option.text = values[i].name
          this.productsTarget.append(option)
      }
    })
  }

  fetchAllProducts(event) {
    event.preventDefault()
  }

  disconnect() {}
}