import { Controller } from 'stimulus'

export default class ReflexModal extends Controller {
  static targets = ["mainModal"]

  initialize () {
    $('#shared-modal').modal({backdrop: 'static', keyboard: false});
  }

  connect() {}
  disconnect() {}
}
