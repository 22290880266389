import Infinite from "./infinite_controller";
import axios from 'axios';
import $ from 'jquery';

export default class ObservableTotalReport extends Infinite {

  initialize() {
		this.params = new URLSearchParams()
		this.isLoading = false
		this.tableData = []
		this.tableBody = this.tableTarget.getElementsByTagName('tbody')[0] || this.tableTarget
		if (this.hasCriteriaTarget) {
      this.criteriaTarget.innerHTML = ""
    }
		this.filter()
	}

  filter() {
    if (this.hasFilterInputTarget) {
      this.filterInputTargets.forEach(function(target) {
        this.params.delete(target.name)
        if(target.tagName === 'SELECT') {
          let selections = target.selectedOptions
          for (let i=0; i< selections.length; i++) {
            this.params.append(target.name, selections[i].value)
          }
        }
        if(target.tagName === 'INPUT') {
          if(target.type === 'checkbox') {
            if (target.checked) {
              this.params.append(target.name, target.value)
            }
          } else {
            this.params.append(target.name, target.value)
          }
        }
      }, this)
    }
    this.setDependLinks()
    this.fetchData(true)
  }

	fetchData(clear = false) {
    if (this.isLoading) return

    this.isLoading = true
    this.displaySpinner()
		if (clear) {
      this.clearOffset()
    }
    axios.get(this.tableTarget.dataset.url, { params: this.params })
    .then(response => {
			if (clear) {
        this.clearBody()
      }
      this.load(response.data.records.menu)
      this.hideSpinner()
			this.displayCriteria(response.data.criteria)
			this.isLoading = false
    })
  }

  load(menu) {
    this.tableTarget.innerHTML = menu
  }

  sort(event) {
    event.preventDefault()

    let sort_name = event.target.dataset.name
    if (this.params.get('sort') === sort_name && this.params.get('order') === 'ASC') {
      this.params.set('order', 'DESC')
    } else {
      this.params.set('order', 'ASC')
    }
    this.params.set('sort', sort_name)

    this.fetchData(true)
  }

	clearBody() {
    this.tableBody.innerHTML = ''
  }

	displayCriteria(criteria) {
		if (criteria === "") {
			this.criteriaTarget.innerHTML = ""
		} else {
      let clearSnippet = `
        <a href="/observe/observable_total_report#" data-action="click->${this.scope.identifier}#clear">
          <i class="fa fa-times-circle text-danger"></i>
        </a>
      `
			this.criteriaTarget.innerHTML = clearSnippet + ' '  + 'Filtered by ' + criteria
		}
	}
}