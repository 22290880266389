import { Controller } from 'stimulus';
import axios from 'axios';

export default class ObservationRubric extends Controller {
  static targets = ["answer", "value", "choice", "box"]

  initialize () {

    this.answerTargets.forEach(function(target) {
      if (target.checked == true) {
        $(target).closest(".rubric-qc").css("background", "#4c87b93d")
      }
      else {
        $(target).closest(".rubric-qc").css("background", "")
      }
    })
  }

  connect() {}

  mouseOver(event) {
    event.preventDefault()
    $(event.target).closest(".rubric-qc").css("border", "3px solid #4c87b9")
  }

  mouseOut(event) {
    event.preventDefault()
    $(event.target).closest(".rubric-qc").css("border", "1px solid lightgrey")
  }

  checkAnswer (event) {
    event.preventDefault()
   let answerId = $(event.target).closest(".rubric-qc")[0].id
   $(event.target).closest(".rubric-qc").css("background", "#4c87b93d")

   this.answerTargets.forEach(function(target) {
      if (target.value == answerId) {
        target.checked = true
        let targetId = "#qc-" + target.value
        $(targetId)[0].checked = true
      }
      else {
        target.checked = false
        $(target).closest(".rubric-qc").css("background", "")
        let targetId = "#qc-" + target.value
        $(targetId)[0].checked = false
      }

    })
  }

  fetchId() { }

  fetchData() {}


  disconnect() {}
}