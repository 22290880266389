import { Controller } from 'stimulus';
import $ from 'jquery';
import axios from 'axios';

export default class ContentArea extends Controller {
  static targets = ["organization", "contentarea", "gradelevel"]

  initialize() {
    $(this.contentareaTarget).select2({
      theme: 'bootstrap',
      tags: true,
      width: '100%',
      tokenSeparators: [',', ' ']
    });
    $(this.contentareaTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });
    $(this.gradelevelTarget).select2({
      theme: 'bootstrap',
      tags: true,
      width: '100%',
      tokenSeparators: [',', ' ']
    });
    $(this.gradelevelTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });
  }

  disconnect() {}

}
