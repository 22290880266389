import { Controller } from 'stimulus';
import $ from 'jquery';
import axios from 'axios';

export default class BulkMessage extends Controller {
  static targets = ["role"]

  initialize() {

    $('#text-preview').hide();

    function showEmailPreview(template) {
      $.ajax({
        headers: {          
          Accept: "text/plain; charset=utf-8",         
        }, 
        type: "post",
        dataType: "json",
        url: '/tenant_users/preview_bulk_message',
        data: {template: template},
        success: function(data) {
          $('#text-preview').html(data.text);
        }
      });
    }
    var el = $('#reseller_observer_template_id');
    $.ajax({
      type: "get",
      url: '/reseller/email_templates/' + el.val(),
      dataType: 'json',
      success: function(data) {
        $('#email_template_from').val(data.from);
        $('#email_template_subject').val(data.subject);
        $('#email_template_html_body').val(data.html_body);
        $('#email_template_text_body').val(data.text_body);
        showEmailPreview(data.text_body);
      }
    });

    $('#email_template_text_body').on('change', function(event) {
      var template = $(event.target).val();
      showEmailPreview(template);
    });
    
    $('#preview-button').click(function() {
      $('#form-table').removeClass('col-xs-12');
      $('#form-table').addClass('col-xs-6');
      $('#text-preview').show();  
    });

    $('#reseller_observer_template_id').on('change', function(event) {
      var el = $('#reseller_observer_template_id');
      $.ajax({
        type: "get",
        url: '/reseller/email_templates/' + el.val(),
        dataType: 'json',
        success: function(data) {
          $('#email_template_from').val(data.from);
          $('#email_template_subject').val(data.subject);
          $('#email_template_html_body').val(data.html_body);
          $('#email_template_text_body').val(data.text_body);
          showEmailPreview(data.text_body);
        }
      });
    });
    
    $('#send-bulk-message').click(function(){
      $('#email-template-form').submit();
    });
  }

  disconnect() {}

}