import { Controller } from 'stimulus'
import $ from 'jquery';

export default class UpdateUserTimeZone extends Controller {
  static targets = ['updateTimeZoneCheck', 'selectedTimeZone', 'currentTimeZoneBlock', 'updateTimeZoneBlock']

  initialize () {
    this.updateTimeZoneBlockTarget.hidden = true;
  }

  checkChange() {
    if (this.updateTimeZoneCheckTarget.checked) {
      this.currentTimeZoneBlockTarget.hidden = true;
      this.updateTimeZoneBlockTarget.hidden = false;
    } else {
      $(this.selectedTimeZoneTarget).select2().val(null).trigger('change');
      this.currentTimeZoneBlockTarget.hidden = false;
      this.updateTimeZoneBlockTarget.hidden = true;
    }
  }

  disconnect() {}
}