import { Controller } from 'stimulus';
import $ from 'jquery';
import axios from 'axios';

export default class BeginObservation extends Controller {
  static targets = ["formset", "organization", "user", "spinner", "body", "footer", 'submit']


  initialize() {
    if (this.hasSpinnerTarget) {
      $(this.spinnerTarget).hide()
    }
    if (this.hasOrganizationTarget) {
      this.fetchData(this.organizationTarget.value)
    }
    if (this.hasUserTarget) {
      this.fetchUserData(this.userTarget.value)
    }
  }

  fetchData(organizationId) {
    axios.get("/observe/form_sets/retrieve_available_form_sets.json", { params: {organization_id: organizationId}})
    .then(response => {

      this.formsetTarget.innerHTML = ""

      let values = response.data

      for(var i=0; i < values.length; ++i ){
          let option = document.createElement("option")
          option.value = values[i].id
          option.text = values[i].name
          this.formsetTarget.append(option)
      }
    })
  }

  fetchUserData(userId) {
    axios.get("/observe/form_sets/retrieve_available_form_sets.json", { params: {tenant_user_id: userId}})
    .then(response => {

      this.formsetTarget.innerHTML = ""

      let values = response.data

      for(var i=0; i < values.length; ++i ){
          let option = document.createElement("option")
          option.value = values[i].id
          option.text = values[i].name
          this.formsetTarget.append(option)
      }
    })
  }

  changeOrganization(event) {
    event.preventDefault()
    this.fetchData(this.organizationTarget.value)
  }

  changeUser(event) {
    event.preventDefault()
    this.fetchUserData(this.userTarget.value)
  }

  submitForm(event){
    event.preventDefault()
    $(this.spinnerTarget).show();
   $(this.bodyTarget).hide();
   $(event.target).hide();
    $(event.target).closest('form').submit();
  }

  disconnect() {}

}