import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    $(this.element).tooltip()

    $(this.element).on('click', function () {
      $(this).tooltip('hide')
    })
  }
}
