import { Controller } from 'stimulus';

export default class HighCharts extends Controller {
  static targets = ["graph", "sparkline", "score"]
  initialize() {
    this.drawGraphs();
    this.sparkLineGraphs();
    this.scoreGraphs();
  }

  connect () {}

  drawGraphs() {
    let n = 0;
    let len = 0;
    let graphs = []

    Highcharts.theme = {
      colors:  ["#3598dc", "#8E44AD", "#4257f5", "#389693", "#26C281", "#9cf542", "#42f5aa", "#f56f42", "#e6f542", "#c89bde", "#c49f47", "#c39de3"],
      title: {
          style: {
              color: '#000',
              font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
          }
      },
      subtitle: {
          style: {
              color: '#666666',
              font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
          }
      },
      legend: {
          itemStyle: {
              font: '9pt Trebuchet MS, Verdana, sans-serif',
              color: 'black'
          },
          itemHoverStyle:{
              color: 'gray'
          }
      },
      plotOptions: {
          area: {
            lineColor: '#666666',
            lineWidth: 1
          },
          column: {
            stacking: 'normal'
          },
          areaspline: {
            stacking: 'percent'
          },
          spline: {
            marker: { symbol: 'circle' },
            marker: {symbol: 'circle'}
          },
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              style: {
                "font":  '9pt Trebuchet MS, Verdana, sans-serif',
                color: 'black'
              }
            }
          }
        },
      credits: {
        enabled: false
      }
    };
    Highcharts.setOptions(Highcharts.theme);

    graphs = Array.from(document.querySelectorAll('div[data-graph]'));

    function doGraphs() {
      n = 0;
      len = graphs.length;
      const time = +new Date();
      for (let i = 0; i < len; i += 1) {
        const graph = graphs[i];
        const data = JSON.parse(graph.dataset.graph);
        const chart = {};

          Highcharts.chart(graph, {
            chart: {
              type:  graph.dataset.type
            },
            title: {
              text: graph.dataset.title
            },
            yAxis: {
              title: {
                text: graph.dataset.ytitle
              },
              max: graph.dataset.max
            },
            xAxis: {
              title: {
                text: graph.dataset.xtitle
              },
              categories: graph.dataset.categories.split(';')[0].split(',')
            },
            plotOptions: {
              series: {
                stacking: graph.dataset.stacking
                }
            },
            series: data
          });

          n += 1;
          if (new Date() - time > 500) {
            graphs.splice(0, i + 1);
            setTimeout(doGraphs(), 0);
            break;
          };
      }
    };
    doGraphs();
  }

  sparkLineGraphs() {
    let n = 0;
    let len = 0;
    let graphs = []
    $('.sparkline-graph').click(function() {
      let id = "$('#"  + this.dataset.id + "')";
      $('#' + this.dataset.id).modal({backdrop: 'static', keyboard: false});
    });

      // SparkLine Graphs
      Highcharts.SparkLine = function (a, b, c) {
          const hasRenderToArg = typeof a === 'string' || a.nodeName;
          let options = arguments[hasRenderToArg ? 1 : 0];
          const defaultOptions = {
            chart: {
              renderTo: (options.chart && options.chart.renderTo) || (hasRenderToArg && a),
              backgroundColor: null,
              borderWidth: 0,
              type: 'area',
              margin: [2, 0, 2, 0],
              width: 120,
              height: 20,
              style: {
                overflow: 'visible'
              },
              // small optimalization, saves 1-2 ms each sparkline
              skipClone: true
            },
          title: {
              text: ''
          },
          navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            credits: {
              enabled: false
            },
            xAxis: {
              labels: {
                enabled: false
              },
              title: {
                  text: null
              },
              startOnTick: false,
              endOnTick: false,
              tickPositions: []
            },
            yAxis: {
              endOnTick: false,
              startOnTick: false,
              labels: {
                enabled: false
              },
              title: {
                  text: null
              },
              tickPositions: [0]
            },
            legend: {
              enabled: false
            },
            tooltip: {
              hideDelay: 0,
              outside: true,
              shared: true
            },
            plotOptions: {
              series: {
                animation: false,
                lineWidth: 1,
                shadow: false,
                states: {
                  hover: {
                    lineWidth: 1
                  }
                },
                marker: {
                  radius: 1,
                  states: {
                    hover: {
                      radius: 2
                    }
                  }
                },
                name: '',
                fillOpacity: 0.25
              },
            }
          };

        options = Highcharts.merge(defaultOptions, options);

        return hasRenderToArg ?
          new Highcharts.Chart(a, options, c) :
          new Highcharts.Chart(options, b);
      };
      graphs = Array.from(document.querySelectorAll('td[data-sparkline]'));

      function doSparkLineGraphs() {
        const start = +new Date();
        const time = +new Date();
        n = 0;
        len = graphs.length;

        for (let i = 0; i < len; i += 1) {
        const graph = graphs[i];
        const data = JSON.parse(graph.dataset.sparkline)
        const chart = {};

          Highcharts.SparkLine(graph, {
            series: [{
              data: data
            }],
            chart: chart
          });

          n += 1;

          // If the process takes too much time, run a timeout to allow interaction with the browser
          if (new Date() - time > 500) {
            graphs.splice(0, i + 1);
            setTimeout(doSparkLineGraphs, 0);
            break;
          }
        }
      };
      doSparkLineGraphs();

  }

  scoreGraphs() {

    let n = 0;
    let len = 0;
    let graphs = []
    $('.question-graph').click(function() {
      let id = "$('#"  + this.dataset.id + "')";
      $('#' + this.dataset.id).modal({backdrop: 'static', keyboard: false});
    });
    graphs = Array.from(document.querySelectorAll('div[data-score]'));

    function doScoreGraphs() {
      n = 0;
      len = graphs.length;
    const time = +new Date();
      for (let i = 0; i < len; i += 1) {
      const score = graphs[i];


      const data = JSON.parse(score.dataset.score);
      const chart = {};

        Highcharts.chart(score, {
          title: {
            text: score.dataset.title
          },
          xAxis: {
            title: {
              text: score.dataset.xtitle
            },
            categories: score.dataset.categories.split(';')[0].split(',')
          },
          yAxis: [{
              labels: {
                format: '{value}'
              },
              title: {
                text: 'Choice Selection %'
              },
              opposite: true,
              max: 100
            }, {
              gridLineWidth: 0,
              title: {
                text: 'Score'
              },
              labels: {
                format: '{value}'
              },
              tickInterval: 0.10,
              max: score.dataset.ymax
            }],
          series: data
        });

        n += 1;

        if (new Date() - time > 500) {
          graphs.splice(0, i + 1);
          setTimeout(doScoreGraphs(), 0);
          break;
        };
      }
    };
    doScoreGraphs();
  }

  disconnect () {}
}